<template>
  <div class=" mb-1">
    <p class="text-center">
      Enter your email address below to send the selected jobs to your email account.
    </p>
    <div class="w-100 d-flex justify-content-center align-items-center">
      <!-- <b-form-input
        v-model="emailInput.value"
        style="width: 200px"
        class=""
        :placeholder="placeholder"
      /> -->

      <!-- <v-select
      v-model="email"
      :options="emailDomains"
      @search="handleSearch"
      :filterable="false"
      :clearable="true"
      :taggable="true"
      placeholder="Enter your email"
    /> -->
      <v-select
        v-model="emailInput.value"
        class="mr-1"
        style="width: 400px"
        :options="filteredOptions"
        :filterable="false"
        :clearable="true"
        :taggable="true"
        :placeholder="placeholder"
        @search="handleSearch"
      >
        <!-- Custom message when no options are found -->
        <template #no-options>
          <div class="custom-message">
            Start typing your email address
          </div>
        </template>
      </v-select>

      <b-button
        :disabled="!emailInput.isValid"
        variant="primary"
        size="md"
        @click="submitForm"
      >Submit</b-button>
    </div>

    <b-modal
      v-model="modals.account_not_exist.state"
      centered
      hide-footer
      title="Share Jobs"
    >
      <div class="d-flex flex-column">
        <div>
          The <b> {{ emailInput.value }} </b> that you have entered doesn’t have
          an existing account our system. We have sent you an email to create
          your account to view your favorited jobs and proceed to complete your
          application at your own ease.
        </div>

        <div class="d-flex justify-content-center">
          <b-button
            class="mt-2"
            variant="primary"
            size="lg"
            @click="
              modals.account_not_exist.state = !modals.account_not_exist.state
            "
          >Close</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select'
// v-model="selectedDomain"
export default {
  components: {
    vSelect,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Your email address',
    },
    value: {
      type: String,
      default: null,
    },
    jobId: {
      type: [String, Array],
      default: null,
    },
  },

  data() {
    return {
      emailInput: {
        value: '',
        isValid: false,
      },
      completeEmail: null,
      modals: {
        account_not_exist: { state: false },
      },
      filteredOptions: [],
      selectedDomain: null,
      emailDomains: [
        'gmail.com',
        'yahoo.com',
        'yahoo.com.sg',
        'outlook.com',
        'icloud.com',
        'hotmail.com',
      ],
    }
  },
  watch: {
    // * both works but the latter should be more efficient
    emailInput: {
      deep: true,
      handler(newValue, _) {
        if (this.$checkEmail(newValue.value).state) {
          this.emailInput.isValid = true
          this.$emit('input', newValue.value)
        } else {
          this.emailInput.isValid = false
        }
      },
    },
    // selectedDomain(newValue, oldValue) {
    //   if (this.emailInput.value.length > 3 && newValue) {
    //     this.emailInput.isValid = true
    //     const fullEmail = `${this.emailInput.value}${newValue}`
    //     this.$emit('input', fullEmail)
    //   } else {
    //     this.emailInput.isValid = false
    //   }
    // },
    // emailInput(newValue, oldValue) {
    //   console.log(newValue)
    //   if (this.$checkEmail(newValue.value).state) {
    //     this.emailInput.isValid = true
    //     this.$emit('input', newValue.value)
    //   } else {
    //     this.emailInput.isValid = false
    //   }
    // },

    value(newValue, oldValue) {
      // this.emailInput.value = newValue
    },
  },

  mounted() {
    this.emailInput.value = ''
  },

  methods: {
    handleSearch(searchTerm) {
      const [localPart, domainPart] = searchTerm.split('@')
      if (domainPart !== undefined) {
        // Filter domain suggestions based on the entered domain part
        this.filteredOptions = this.emailDomains.map(
          domain => `${localPart}@${domain}`,
        ).filter(option => option.includes(searchTerm))
      } else {
        // Clear suggestions when no @ symbol is found
        this.filteredOptions = []
      }
    },
    submitForm() {
      this.$emit('generateCode', null)
      // return
      // this.$http
      //   .post('/api/user/kiosk-share-job', {
      //     form_data: {
      //       key: process.env.VUE_APP_RANDOM,
      //       email_address: this.emailInput.value,
      //       job_id: this.jobId,
      //     }
      //   })
      //   .then(response => {
      //     const { success } = response.data

      //     if (!success) {
      //       this.$set(this.modals.account_not_exist, 'state', true)
      //     }
      //   })
    },
  },
}
</script>

<style>
</style>
