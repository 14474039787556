export default [ 

  // * KIOSK
  {
    path: '/kiosk',
    name: 'kiosk-page',
    component: () => import('@/views/event-management-system/Listings.vue'),
    meta: {
      allowAnonymous: true,
      public: true,
      layout: 'full',
    },
  },

   {
    path: '/landing',
    name: 'splash-page',
    component: () => import('@/views/event-management-system/SplashPage.vue'),
    meta: {
      allowAnonymous: true,
      public: true,
      layout: 'full',
    },
  },
  {
    path: '/shortn/:unique_id',
    name: 'url-shortn-page',
    component: () => import('@/views/event-management-system/Shorten.vue'),
    meta: {
      allowAnonymous: true,
      public: true,
      layout: 'full',
    },
  },

  {
    path: '/kiosk/events/:company?',
    name: 'kiosk-company-page',
    component: () => import('@/views/event-management-system/Listings.vue'),
    meta: {
      allowAnonymous: true,
      public: true,
      layout: 'full',
    },

  },
  {
    path: '/kiosk/job/:eventid?',
    name: 'kiosk-page-event',
    component: () => import('@/views/event-management-system/ListEvent.vue'),
    meta: {
      allowAnonymous: true,
      public: true,
      layout: 'full',
    },
  },

  {
    path: '/kiosk/events/:eventid?/:dashboard?',
    name: 'kiosk-page-event-dashboard',
    component: () => import('@/views/event-management-system/ListEvent.vue'),
    meta: {
      allowAnonymous: true,
      public: true,
      layout: 'full',
      // layout: to => ({
      //   'full': to.params.dashboard === null, // Set layout to 'full' if company exists
      //   'full': to.params.dashbaord === 'dashboard', // Set layout to 'partial' if company is null
      // }),
    },
    beforeEnter: (to, from, next) => {
      // Check if the 'company' parameter is present in the route
      if (to.params.dashboard) {
        // If 'company' is not null, set layout to 'full'
        to.meta.layout = 'partial';
      } else {
        // If 'company' is null, set layout to 'partial'
        to.meta.layout = 'full';
      }

      // Continue with the navigation
      next();
    },
  },
]
