<template>
  <b-container class="d-flex justify-content-center">
    <h3>{{ message }}</h3>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      uniqueID: null,
      message: '',
    }
  },
  created() {
    const uniqueID = this.$route.params.unique_id
    if (uniqueID) {
      this.message = 'You will be redirected shortly....'
      this.getShortenedURL(uniqueID)
    } else {
      this.message = 'Expired or invalid link'
    }
  },
  methods: {
    getShortenedURL(uniqueID) {
      this.$http.get(
        `/api/shorten-link/${uniqueID}`,
      ).then(response => {
        const { link } = response.data
        window.location.href = link
      })
        .catch(error => {
          this.message = 'Expired or invalid link'
        })
    },

  },

}
</script>
