/* eslint-disable prefer-destructuring */ /* eslint-disable no-trailing-spaces
*/ /* eslint-disable no-restricted-syntax */
<template>
  <div id="div-with-loading" class="w-100" @mousemove="resetInactivityTimer" @keydown="resetInactivityTimer">
    <!-- <b-button
      @click="kiosk.isKiosk = !kiosk.isKiosk"
    >show</b-button> -->
    <div class="sticky-navbar">
      <AppNavbarFull v-if="showLayout" ref="refAppNavbarFull" :company-image="appNavbarFullImageUrl" :kiosk="kiosk"
        @next-step="shareKioskJob" />

      <b-row class="mx-0 p-0" style="background-color: white" />
    </div>

    <!-- <b-row
      v-if="pageType != 'general'"
      class="mx-2 mt-2"
    >
      <b-button
        variant="primary"
        :class="kiosk.isKiosk ? '' : 'mx-1'"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="goBack"
      >Go Back</b-button>
    </b-row> -->

    <b-row v-if="specific_company">
      <b-card v-if="specific_company_image" class="w-100 mx-auto">
        <div class="">
          <h3>All events by :</h3>
        </div>

        <div class="">
          <img :src="specific_company_image" alt="content-img" class="image-size mx-auto self-center" />
        </div>
      </b-card>
    </b-row>

    <div v-if="false" class="d-flex justify-content-center mt-1 mb-3 mx-2">
      <img v-if="group_event_image !== 'default'" :src="group_event_image" alt="content-img" class="image-size mx-auto"
        @click="checkRedirection" />
    </div>

    <div class="my-2">
      <div class="p-8">
        <div class="container-fluid" :class="kiosk.isKiosk ? '' : 'px-2 px-md-4'">
          <b-modal id="modal-lg" ref="share-job-modal" hide-footer title="Share Jobs">
            <div class="d-flex flex-column">
              <h6 class="mx-auto">The link that will be shared.</h6>
              <b-form-input v-model="share_link" class="mt-1 mx-auto" />
            </div>
            <div class="d-flex flex-wrap justify-content-center p-1">
              <ShareNetwork v-for="(info, index) of all_share_icons" :key="index" class="mx-1" :network="info.network"
                :url="getLink(current_item.id)" :title="'Check out this ' +
                  current_item.position +
                  ' event organised by ' +
                  current_item.company_name +
                  ' via HeadHunters HQ.'
                  ">
                <font-awesome-icon :icon="info.name" size="3x" :class="info.styling" />
              </ShareNetwork>
            </div>
          </b-modal>

          <b-modal id="modal-lg" ref="share-kiosk-job-modal" v-model="showLoa" hide-footer size="lg"
            title="Letter Of Authorization">
            <iframe src="/loa.pdf" height="600" width="760" frameborder="0"></iframe>

          </b-modal>

          <b-modal id="modal-lg" ref="share-kiosk-job-modal" v-model="modals.share_media_selection.state" hide-footer
            size="lg" title="Share Jobs">
            <div class="p-2">
              <div>
                <h3 class="text-center">
                  How do you wish to receive your shortlisted jobs?
                </h3>
              </div>

              <div class="d-flex justify-content-center">
                <div v-for="(info, index) of all_kiosk_share_icons" :key="index" class="mx-5">
                  <font-awesome-icon :icon="info.name" size="7x" :class="`${info.styling} ${!modals.share_media_selection.selected_media
                    ? 'opacity-25'
                    : modals.share_media_selection.selected_media !== info.network
                      ? 'opacity-25'
                      : 'opacity-100'
                    }`" @click="activateShareFunc(info, current_item)" />
                </div>

                <!-- <ShareNetwork
                    v-for="(info, index) of all_kiosk_share_icons"
                    :key="index"
                    class="mx-1"
                    :network="info.network"
                    :url="getKioskLink(current_item.id)"
                    :title="
                      'Check out this ' +
                        current_item.position +
                        ' on HeadHunters HQ.'
                    "
                  >
                    <font-awesome-icon
                      :icon="info.name"
                      size="3x"
                      :class="info.styling"
                    />
                  </ShareNetwork> -->
              </div>
            </div>
            <div v-if="!$store.state.auth.accept_loa">

              <div class="d-flex justify-content-center mt-1">
                <b-form-checkbox v-model="acceptLoa" />
                <p class="font-weight-bolder">I agree to the </p> <a class="text-primary" style="margin-left: 3.5px;margin-top: -0.1px;" @click="showLoa = true"> Terms & Conditions and Privacy
                  Policy</a>.
              </div>

            </div>

            <div v-else>
              <div v-if="show_email_input">
                <ShareJobEmail v-model="email_input" :job-id="current_item.id" @generateCode="showEmailQRCode" />
              </div>

              <div v-if="show_qr_code" class="d-flex flex-wrap justify-content-center">
                <p v-if="modals.share_media_selection.selected_media === 'whatsapp'">
                  Generate & scan the QR code below to send the selected jobs to your
                  Whatsapp account.
                </p>
                <p v-else-if="modals.share_media_selection.selected_media === 'telegram'">
                  Scan the QR code below to send the selected jobs to your
                  {{ $capitalizeString(qr_code_info.network) }} account.
                </p>
                <p v-else>
                  Enter your email address below to send the selected jobs to your email account.
                </p>
                <div v-if="
                  modals.share_media_selection.selected_media === 'whatsapp'
                " class="w-100 d-flex flex-row flex-gap-sm justify-content-center">
                  <div>
                    <b-input-group size="md" variant="primary" prepend="+65">
                      <b-form-input v-model="modals.share_media_selection.phone_number" type="number" :state="$checkPhoneNumber(
                        modals.share_media_selection.phone_number
                      ).state
                        " danger-text="Please enter an 8 digit Phone Number" placeholder="Input your Phone Number"
                        maxlength="8" />
                    </b-input-group>

                    <b-form-invalid-feedback tooltip>
                      {{
                        $checkPhoneNumber(
                          modals.share_media_selection.phone_number
                        ).message
                      }}
                    </b-form-invalid-feedback>
                  </div>
                  <b-button :disabled="!$checkPhoneNumber(
                    modals.share_media_selection.phone_number
                  ).state
                    " style="width: 200px" variant="primary" @click="
                      generateQRCode(
                        all_kiosk_share_icons[
                        modals.share_media_selection.selected_media ===
                          'whatsapp'
                          ? 0
                          : 1
                        ],
                        current_item
                      )
                      ">
                    Generate QR
                  </b-button>
                </div>
              </div>

              <div class="d-flex justify-content-center mb-2">
                <qrcode v-if="qr_code_value" class="mx-auto mt-1" :value="qr_code_value" :options="{ width: 300 }" />
              </div>

              <div v-if="$store.state.auth.share_job_list.length !== 0" class="mt-3">
                <h6>Roles you have selected to share and apply later:</h6>
                <ul class="overflow-auto-vertical" style="max-height: 20cm">
                  <hr />
                  <li v-for="(item, index) in $store.state.auth.share_job_list" :key="index" class="mt-1">
                    <b-row>
                      <b-col cols="10" class="job-item">
                        <div class="d-flex flex-gap-xs align-items-center mb-1">
                          <font-awesome-icon icon="building" class="text-secondary" style="font-size: 16px" />
                          <span>{{ item.company }}</span>
                        </div>
                        <div class="d-flex align-items-center">
                          <h5>
                            <strong>{{ item.position }}</strong>
                          </h5>
                        </div>

                        <div class="d-flex">
                          <div class="d-flex flex-gap-xs justify-content-between align-items-center mr-1">
                            <FeatherIcon icon="MonitorIcon" size="16" style="
                                font-weight: bold !important;
                                stroke-width: 3;
                              " />
                            <span>{{ item.interview_type }}</span>
                          </div>

                          <div class="d-flex flex-gap-xs justify-content-between align-items-center mx-1">
                            <font-awesome-icon icon="map-marker-alt" class="" style="font-size: 16px" />
                            <span>{{ item.location.replace('()', '') }}</span>
                          </div>

                          <div class="d-flex flex-gap-xs justify-content-between align-items-center mx-1">
                            <font-awesome-icon icon="dollar-sign" class="" style="font-size: 16px" />
                            <span>{{ item.salary }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="1">
                        <b-button variant="primary" class="btn-icon mt-2" @click="removeJob(item.id)">
                          <feather-icon icon="TrashIcon" size="28" />
                        </b-button>
                      </b-col>
                    </b-row>

                    <hr class="mb-1" />
                  </li>
                </ul>
              </div>
            </div>

            <!-- <div class="d-flex flex-column">
              <h6 class="mx-auto">
                The link that will be shared.
              </h6>
              <b-form-input
                v-model="share_link"
                class="mt-1 mx-auto"
              />
            </div> -->
          </b-modal>
        </div>

        <!-- Mobile Filters -->
        <div :class="{ 'd-none': isMobile && kiosk.isKiosk }" class="filter-button-container d-none">
          <div class="filter-button-box">
            <b-button class="filter-button btn-icon" :class="{ 'w-100': isMobile }" :variant="'primary'"
              @click="resetFilters">
              <feather-icon icon="XCircleIcon" style="margin-right: 4px; margin-bottom: 1px" />
              <span class="align-middle">Reset Filters</span>
            </b-button>

            <b-button class="filter-button btn-icon" :class="{ 'w-100': isMobile }" :variant="$isNotEmpty(select_job_source) ? 'primary' : 'outline-primary'
              " @click="filterModals.job_source.state = true">
              <span class="align-middle">Job Source</span>
            </b-button>

            <b-button v-if="pageType !== 'company'" class="filter-button btn-icon" :class="{ 'w-100': isMobile }"
              :variant="$isNotEmpty(select_company) ? 'primary' : 'outline-primary'
                " @click="filterModals.employer_company_name.state = true">
              <span class="align-middle">Employer Name</span>
            </b-button>

            <b-button class="filter-button btn-icon" :class="{ 'w-100': isMobile }" :variant="$isNotEmpty(selected_location) ? 'primary' : 'outline-primary'
              " @click="filterModals.work_location.state = true">
              <span class="align-middle"> Work Location</span>
            </b-button>

            <b-button class="filter-button btn-icon" :class="{ 'w-100': isMobile }" :variant="$isNotEmpty(salary_range) && !isDefaultSalaryRange()
              ? 'primary'
              : 'outline-primary'
              " @click="filterModals.salary.state = true">
              <span class="align-middle"> Salary Range </span>
            </b-button>

            <b-button class="filter-button btn-icon" :class="{ 'w-100': isMobile }" :variant="$isNotEmpty(selected_job_type) ? 'primary' : 'outline-primary'
              " @click="filterModals.job_type.state = true">
              <span class="align-middle">Job Type</span>
            </b-button>

            <b-button class="filter-button btn-icon" :class="{ 'w-100': isMobile }" :variant="$isNotEmpty(selected_employment_type)
              ? 'primary'
              : 'outline-primary'
              " @click="filterModals.employment_type.state = true">
              <span class="align-middle"> Employment Type</span>
            </b-button>

            <b-button class="filter-button btn-icon" :class="{ 'w-100': isMobile }" :variant="$isNotEmpty(selected_industry) ? 'primary' : 'outline-primary'
              " @click="filterModals.employer_industry.state = true">
              <span class="align-middle">Employer Industry</span>
            </b-button>

            <b-button class="filter-button btn-icon" :class="{ 'w-100': isMobile }" :variant="$isNotEmpty(selected_job_function)
              ? 'primary'
              : 'outline-primary'
              " @click="filterModals.job_function.state = true">
              <span class="align-middle">Job Function</span>
            </b-button>

            <b-button v-if="false" class="filter-button btn-icon" :class="{ 'w-100': isMobile }" :variant="$isNotEmpty(sort_choice) && sort_choice.lower() !== 'default'
              ? 'primary'
              : 'outline-primary'
              " @click="filterModals.sort.state = true">
              <span class="align-middle"> Sort By</span>
            </b-button>
          </div>
        </div>

        <div class="d-flex w-100 mt-1">
          <!-- <div v-for="(group, i) in eventsGroups" :key="i"> -->
          <!-- .slice(i * 4, (i + 1) * 4) -->

          <div id="sidebarFilters" class='ml-1' style="position: sticky; top: 90px; height: 350rem; width: 35%">
            <b-overlay :show="searchLoading && !viewMoreLoading" rounded="sm">
              <div class="d-flex justify-content-center" style="background-color: #F37044;border-radius: 2%;">
                <h3 class="text-white my-3">Cart-A-Job-Home Today!</h3>
              </div>
              <b-card :class="{ '': isMobile || !kiosk.isKiosk }" class="">
                <div class="w-100">
                  <div class="d-flex flex-row flex-wrap mx-1" :class="expand_search
                    ? 'justify-content-between'
                    : 'justify-content-end'
                    ">


                    <b-button v-if="false" class="btn-icon d-block d-lg-none" :class="{ 'w-100': isMobile }"
                      variant="primary" @click="expand_search = !expand_search">
                      <feather-icon :icon="expand_search ? 'Minimize2Icon' : 'Maximize2Icon'
                        " class="" />
                      <span class="align-middle">{{
                        expand_search ? "" : "Maximize Filters Panel"
                      }}</span>
                    </b-button>
                  </div>
                  <b-form-group class="container-fluid">
                    <div v-if="false && expand_search" class="mt-2 mb-1">
                      <span :class="`${filterTitleClass}`">
                        Sort Positions By
                      </span>

                      <v-select v-model="sort_choice" label="value" class="" :options="sort_options"
                        :reduce="(value) => value.value" />
                    </div>

                    <div v-if="false" class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`"> Job Source </span>

                      <FilterJobSource :options="jobSources" :selected-value="select_job_source"
                        @update="(e) => (select_job_source = e)" />
                    </div>

                    <div v-if="false && pageType !== 'company'" class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`">
                        Employer Name
                      </span>

                      <!-- <FilterEmployer
                          :options="companies"
                          :selected-value="select_company"
                          @update="(e) => select_company = e"
                        /> -->

                      <v-select v-model="select_company" placeholder="Start typing to search the company name" multiple
                        label="value" class="text-white" :loading="companySearchLoading" :options="companies"
                        :reduce="(value) => value.value" @search="companySearch" @open="companySearch" />
                    </div>

                    <div v-if="
                      !specific_company &&
                      organiser_companies.length > 0 &&
                      expand_search
                    " class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`">
                        Event Organiser Name
                      </span>
                      <v-select v-model="select_organiser" placeholder="Choose an event organiser"
                        :options="organiser_companies" multiple label="value" class="text-white"
                        :reduce="(value) => value.value" />
                    </div>

                    <div v-if="false && expand_search" class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`"> Salary Range </span>
                      <FilterSalary v-if="renderFilter" :salary-range="salary_range"
                        :min-salary-range="min_salary_range" :max-salary-range="max_salary_range"
                        @update="(e) => (salary_range = e)" />
                    </div>

                    <div v-if="false && expand_search" class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`"> Job Type </span>

                      <FilterJobType :options="job_types" :selected-value="selected_job_type"
                        @update="(e) => (selected_job_type = e)" />
                    </div>

                    <div v-if="expand_search" class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`"> Search by Keyword </span>
                      <b-input-group class="bg-white">
                        <b-form-input v-if="
                          $route.params.company ===
                          'e88e4812-82c8-4d49-8aa1-0b3e3129bb43_g' ||
                          $route.params.company ===
                          '61de74bc21c14eb016e518cb_g'
                        " id="vSelectPositionSearch" v-model="selected_event_title" class="w-auto"
                          :class="{ 'w-75': isMobile }" placeholder="Enter Keyword" debounce="1000"
                          @keyup="searchText" />

                        <!-- <b-input-group-append
                            :class="{'w-25': isMobile}"
                          >
                            <b-button
                              variant="primary"
                              class="w-100"
                              @click="specificGetEvents()"
                            >
                              <feather-icon
                                class="mr-md-1"
                                icon="SearchIcon"
                                size="15"
                              />
                              <span v-if="!isMobile">Search</span>
                            </b-button>
                          </b-input-group-append> -->

                        <v-select v-else-if="renderPositionSearch" id="vSelectJobPosition"
                          v-model="selected_event_title" style="width: 100% !important" class="w-auto"
                          :class="{ 'w-75': isMobile }" placeholder="Enter Keyword" :options="all_positions"
                          @open="jobPositionSearch" @close="searchText" @search="jobPositionSearch" />
                      </b-input-group>
                    </div>

                    <div v-if="expand_search" class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`">
                        Employment Type
                      </span>

                      <FilterEmploymentType :options="employment_types" :selected-value="selected_employment_type"
                        @update="(e) => (selected_employment_type = e)" />
                    </div>

                    <div v-if="all_job_functions.length > 0 && expand_search" class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`"> Job Function </span>

                      <FilterJobFunction :options="all_job_functions" :selected-value="selected_job_function"
                        @update="(e) => (selected_job_function = e)" />
                    </div>

                    <div v-if="expand_search" class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`">
                        Industry
                      </span>

                      <!-- <FilterEmployerIndustry
                          :options="industries"
                          :selected-value="selected_industry"
                          @update="(e) => selected_industry = e"
                        /> -->

                      <v-select v-model="selected_industry" placeholder="Select Industry" multiple label="value"
                        :loading="industrySearchLoading" :options="industries" class="text-white"
                        :reduce="(value) => value.value" @search="industrySearch" @open="industrySearch" />
                    </div>
                    <div v-if="expand_search" class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`">
                        Work Location
                      </span>

                      <FilterWorkLocation :options="all_locations" :selected-value="selected_location"
                        @update="(e) => (selected_location = e)" />
                    </div>
                    <div v-if="
                      !specific_company &&
                      all_dates.length > 0 &&
                      expand_search
                    " class="mt-1 mb-1">
                      <span :class="`${filterTitleClass}`">
                        Date Of Event
                      </span>
                      <v-select v-model="select_date" label="value" placeholder="Choose the start date of the event"
                        :options="all_dates" multiple class="text-white" :reduce="(value) => value.value" />
                    </div>
                    <b-button v-if="expand_search" class="align-left w-100" :class="{ 'w-100': isMobile }" block
                      variant="outline-primary" @click="resetFilters">
                      <feather-icon icon="XCircleIcon" style="margin-right: 4px; margin-bottom: 1px" />
                      <span class="align-middle">Reset Filters</span></b-button>
                  </b-form-group>
                </div>
              </b-card>
            </b-overlay>

            <KioskFooter v-if="$isNotEmpty(kiosk) && kiosk.isKiosk" :kiosk="kiosk" />
          </div>

          <div style="width: 65%">
            <!-- style="min-height: 65vh;" -->
            <b-overlay :show="jobsLoading" rounded="sm">
              <b-col v-if="events_data && events_data.length > 0" id="jobs_loading" class="w-100">
                <div v-for="(item, index) in events_data" :key="index" class="w-100">
                  <b-card class="job-card d-flex flex-column">
                    <b-row class="m-0 p-0 p-md-1">
                      <b-col lg="12" sm="12" class="m-0 p-0 pr-1">
                        <div class="">
                          <b-badge v-if="item.featured && !isFiltered" class="mb-1 bg-main" style="width: 13ch">
                            <feather-icon icon="StarIcon" />&nbsp; Featured
                          </b-badge>

                          <div v-if="item.published_fields.includes('employer')" class="d-flex flex-wrap">
                            <h6 v-if="item.event_category === 'join'" class="m-0 p-0 cursor-pointer">
                              <a class="style-inherit" :href="kiosk.isKiosk
                                ? $router.resolve({
                                  name: 'kiosk-company-page',
                                  params: { company: item.company_id },
                                }).href
                                : $router.resolve({
                                  name: 'company-listings-page',
                                  params: { company: item.company_id },
                                }).href
                                ">
                                <span>{{ item.company_name }}</span>
                              </a>
                            </h6>
                            <h5 v-else class="m-0 p-0 cursor-pointer">
                              {{ item.company_name }}
                            </h5>
                          </div>

                          <div v-else class="d-flex flex-wrap">
                            <h6 v-if="item.event_category === 'join'" class="m-0 p-0 font-italic">
                              Employer Confidential
                            </h6>
                            <h5 v-else class="m-0 p-0 font-italic">
                              Employer Confidential
                            </h5>
                          </div>

                          <div class="mt-1 d-flex flex-gap-sm flex-row align-items-center">
                            <h2 class="text-primary m-0 p-0" style="font-weight: 400">
                              {{ item.position }}
                            </h2>
                          </div>
                          <div v-if="item.event_name && item.event_active" class="mt-1">
                            <p v-if="item.organiser_name && !grouped()" class="m-0 p-0"
                              style="font-weight: 600; font-size: 14px">
                              <a :href="kiosk.isKiosk
                                ? $router.resolve({
                                  name: 'kiosk-company-page',
                                  params: {
                                    company: item.event_id + '_g',
                                  },
                                }).href
                                : $router.resolve({
                                  name: 'company-listings-page',
                                  params: {
                                    company: item.event_id + '_g',
                                  },
                                }).href
                                ">{{ item.event_name }}</a>
                              event
                              {{
                                cleanerIntervals(
                                  item.cleaned_start_date,
                                  item.cleaned_end_date
                                )
                              }}
                              ({{ item.cleaned_start_time }} to
                              {{ item.cleaned_end_time }})
                            </p>
                          </div>

                          <div class="d-flex flex-wrap flex-gap-md mt-1" style="font-weight: 600; font-size: 14px">
                            <div class="d-flex flex-gap-xs justify-content-between">
                              <FeatherIcon icon="MonitorIcon" size="16" style="
                                  font-weight: bold !important;
                                  stroke-width: 3;
                                " />
                              <h6 class="m-0 p-0 icon-header">
                                {{ getEventType(item.interview_type) }}
                              </h6>
                            </div>

                            <div class="d-flex flex-gap-xs justify-content-between">
                              <font-awesome-icon icon="map-marker-alt" style="font-size: 16px" />
                              <h6 class="m-0 p-0 icon-header">
                                {{
                                  cleanLocation(
                                    item.work_location,
                                    item.location_zone
                                  )
                                }}
                              </h6>
                            </div>

                            <div v-if="
                              item.source_name !== 'efc' &&
                              item.published_fields.includes('salary')
                            " class="d-flex flex-gap-xs justify-content-between">
                              <font-awesome-icon icon="dollar-sign" style="font-size: 16px" />

                              <h6 v-if="item.min_salary == item.max_salary" class="m-0 p-0 icon-header">
                                <!-- {{
                                  item.max_salary === 1 || item.max_salary === "1" ? "Undisclosed" : item.max_salary
                                }}
                                {{
                                  item.max_salary === 1 || item.max_salary === "1"
                                    ? ""
                                    : cleanCycles(item.salary_cycle)
                                }} -->

                                {{
                                  $isNotEmpty(item.min_salary) &&
                                    $isNotEmpty(item.max_salary)
                                    ? `${item.min_salary} - ${item.max_salary
                                    } ${cleanCycles(item.salary_cycle)}`
                                    : "Undisclosed"
                                }}
                              </h6>

                              <h6 v-else-if="
                                item.max_salary === 'To be discussed later'
                              " class="m-0 p-0 icon-header">
                                {{ item.max_salary }}
                              </h6>

                              <h6 v-else class="m-0 p-0 icon-header">
                                {{
                                  $isNotEmpty(item.min_salary)
                                    ? `${item.min_salary} - ${item.max_salary
                                    } ${cleanCycles(item.salary_cycle)}`
                                    : "Undisclosed"
                                }}
                              </h6>
                            </div>
                            <div v-else class="d-flex flex-gap-xs justify-content-between">
                              <font-awesome-icon icon="dollar-sign" class="font-italic" />

                              <h6 class="m-0 p-0 icon-header font-italic">
                                Salary Confidential
                              </h6>
                            </div>

                            <div class="d-flex flex-gap-xs justify-content-between">
                              <font-awesome-icon icon="users" style="font-size: 16px" />
                              <h6 class="m-0 p-0 icon-header">
                                {{
                                  !item.external
                                    ? registered[item.id].length
                                    : item.applications
                                }}
                                Applications
                              </h6>
                            </div>
                          </div>

                          <div v-if="item.industry && item.industry.length > 0" class="d-flex mt-1">
                            <div class="d-flex flex-wrap flex-gap-xs">
                              <div v-for="(tag, index) in item.industry" :key="index">
                                <b-badge pill variant="dark">
                                  {{ tag }}
                                </b-badge>
                              </div>
                            </div>
                          </div>

                          <h6 v-if="!item.organiser_name && !kiosk.isKiosk" class="m-0 p-0 mt-1 text-danger"
                            style="font-size: 14px">
                            <span>When you click `Apply Now` button you will be
                              redirected to NTUC's Job Security Council - Jobs
                              Alert telegram bot where you will complete the
                              application.</span>
                          </h6>

                          <div v-if="['mcf', 'efc'].includes(item.source_name)"
                            class="mt-1 d-flex flex-gap-sm align-items-center">
                            <h6 class="m-0 p-0 text-warning font-weight-bold" style="font-weight: 600 !important">
                              Source:
                              {{
                                item.source_name === "mcf"
                                  ? "MyCareersFuture"
                                  : "eFinancialCareers"
                              }}
                            </h6>
                            <font-awesome-icon v-b-tooltip.hover.top="mcf_message" icon="question-circle"
                              class="text-warning" />
                          </div>

                          <!-- <p
                            class="m-0 p-0 fw-bold text-right"
                            style="font-weight: 400; font-size: 14px"
                          >
                            {{ !item.external ? registered[item.id].length : item.applications }} Applications
                          </p> -->
                        </div>
                      </b-col>

                      <b-col lg="12" sm="12" class="m-0 p-0 mt-1 mt-md-0">
                        <div class="">
                          <div class="divider bg-warning d-block d-md-none" />

                          <div class="d-flex align-items-stretch w-100 mt-2">
                            <div v-if="item.event_category !== 'group'" class="" style="width: 50%">
                              <b-button :id="`buttonViewMore_${item.id}`" class="mx-auto" :to="kiosk.isKiosk
                                ? '/kiosk/job/' + item.id
                                : '/listing/' +
                                item.id +
                                `${$route.name !== 'listings-page'
                                  ? '/dashboard'
                                  : ''
                                }`
                                " style="
                                  border-radius: 5px !important;
                                  height: 30px;
                                " variant="outline-primary" block>
                                <div class="d-flex justify-content-center align-items-center"
                                  style="margin-top: -7px !important;border-radius: 5px !important;">
                                  <feather-icon icon="BookOpenIcon" class="mr-1" style="margin-top: -15px !important" />
                                  <p style="">View Job Description</p>
                                </div>
                              </b-button>
                            </div>

                            <div class="ml-1" style="width: 50%">
                              <KioskShareJobButton v-if="kiosk.isKiosk" :job-details="item" :icon="'PlusIcon'"
                                :variant="'primary'" style="" @next-step="shareKioskJob(item)" />

                              <b-button v-else class="mx-auto w-100" variant="primary" block
                                style="border-radius: 5px !important" @click="shareEvent(item)">
                                <feather-icon icon="ShareIcon" class="mr-1" />
                                <span class="align-middle">Share Jobs</span>
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <hr v-if="item.note" />
                    <b-row v-if="item.note">
                      <p style="
                          font-weight: 600;
                          font-size: 14px;
                          white-space: pre-line;
                        " class="mx-2 mt-1">
                        <span> Note: </span>
                        <br />
                        <span>
                          {{ item.note }}
                        </span>
                      </p>
                    </b-row>
                  </b-card>
                </div>

                <div v-if="events_data.length > 0" class="d-flex w-100 mb-16" style="margin-bottom: 100px">
                  <SpinnerBtn v-if="viewMoreLoading" class="mx-auto" :variant="'primary'" style="width: 18ch"
                    :block="false" />
                  <b-button v-else-if="!loadedAllJobs" class="mx-auto" disabled variant="primary" style="width: 28ch;height:20px;"
                    @click="loadMoreJobs"></b-button>
                  <b-button v-else class="mx-auto" variant="primary" disabled style="width: 28ch">That's All For
                    Now</b-button>

                  <!-- <vs-pagination
                  class="vx-col sm:ml-12 lg:ml-0"
                  :total="total_events.length"
                  v-model="current_job_page"
                  :max="10"
                ></vs-pagination> -->
                </div>
              </b-col>
              <b-col v-else class="w-100">
                <h4 class="grey mx-auto text-center mt-5" :class="accountancy_event ? 'text-white' : 'text-dark'">
                  {{
                    event_exist
                      ? "No available jobs under this section currently."
                      : "There are no available job listings in this category"
                  }}
                </h4>
              </b-col>
            </b-overlay>
          </div>
        </div>
        <!-- </div> -->
      </div>

      <!-- RegistrationForm Modal -->
      <div class="">
        <b-modal ref="popupReg" v-model="popupReg" centered hide-footer title="Join Event" size="lg">
          <div class="w-100">
            <h6 class="mb-6">
              You will require a HeadHunters HQ account to proceed with the
              application.
            </h6>
            <b-tabs justified>
              <b-tab title="Login">
                <login-page class="w-100" which-page="listings" :event-id="this.$router.currentRoute.path"
                  @user-logged-in="afterUserLoggedIn" />
              </b-tab>

              <b-tab title="Registration">
                <RegistrationForm class="w-100" :extra-data="popup_questions" :event-id="event_id" which-page="listings"
                  account-type="Applicant" @user-registered="afterUserRegistered" />
              </b-tab>
            </b-tabs>
          </div>
        </b-modal>
      </div>

      <!-- Quiz Modal -->
      <!-- <JobPopup
        :activate-popup="popup_quiz"
        :job-questions="popup_questions"
        :save-nric="save_nric"
        :all-applications="registered"
        @application-form="checkForm"
        @application-id="setApplicationId"
      />

      <ProfileCompletionForm
        :activate-popup="popupProfileQuestions"
        @open-job-form="getQuestions"
        @open-completion-form="activateTypeForm"
      /> -->

      <!-- Filter Modals -->
      <template>
        <b-modal id="modal-lg" ref="filter-job-modal" v-model="filterModals.job_type.state" centered hide-footer
          title="Job Type">
          <div class="mt-1 mb-1">
            <FilterJobType :options="job_types" :selected-value="selected_job_type"
              @update="(e) => (selected_job_type = e)" />
          </div>
        </b-modal>

        <b-modal id="modal-lg" ref="filter-position-modal" v-model="filterModals.employer_industry.state" centered
          hide-footer title="Employment Industry">
          <div class="mt-1 mb-1">
            <!-- <FilterEmployerIndustry
              :options="industries"
              :selected-value="selected_industry"
              @update="(e) => selected_industry = e"
            /> -->

            <v-select v-model="selected_industry" placeholder="Choose your preferred employer industry" multiple
              label="value" :loading="industrySearchLoading" :options="industries" class="text-white"
              :reduce="(value) => value.value" @search="industrySearch" @open="industrySearch" />
          </div>
        </b-modal>

        <b-modal id="modal-lg" ref="filter-job-source-modal" v-model="filterModals.job_source.state" centered
          hide-footer title="Job Source">
          <div class="mt-1 mb-1">
            <FilterJobSource :options="jobSources" :selected-value="select_job_source"
              @update="(e) => (select_job_source = e)" />
            <!-- <v-select
              v-model="select_job_source"
              placeholder="Choose the job source"
              multiple
              label="value"
              class="text-white"
              :options="jobSources"
              :reduce="(value) => value.value"
            /> -->
          </div>
        </b-modal>

        <b-modal id="modal-lg" ref="filter-employer-modal" v-model="filterModals.employer_company_name.state" centered
          hide-footer title="Employer Name">
          <div class="mt-1 mb-1">
            <!-- <FilterEmployer
              :options="companies"
              :selected-value="select_company"
              @update="(e) => select_company = e"
            /> -->
            <v-select v-model="select_company" placeholder="Start typing to search the company name" multiple
              label="value" class="text-white" :loading="companySearchLoading" :options="companies"
              :reduce="(value) => value.value" @search="companySearch" @open="companySearch" />
          </div>
        </b-modal>

        <b-modal id="modal-lg" ref="filter-work-modal" v-model="filterModals.work_location.state" centered hide-footer
          title="Work Location">
          <div class="mt-1 mb-1">
            <FilterWorkLocation :options="all_locations" :selected-value="selected_location"
              @update="(e) => (selected_location = e)" />
          </div>
        </b-modal>

        <b-modal id="modal-lg" ref="filter-salary-modal" v-model="filterModals.salary.state" centered hide-footer
          title="Salary Range">
          <div class="mt-1 mb-1">
            <!-- <h6 class="">
              Per Annum
            </h6>
            <vue-slider
              v-model="salary_range_yearly"
              :enable-cross="false"
              class="w-100 mt-3"
              :contained="true"
              :silent="true"
              :tooltip="'always'"
              :min="min_salary_range"
              :max="max_salary_range"
              :interval="1"
              :tooltip-formatter="salary_formatter"
            />
            <h6 class="">
              Per Monthly
            </h6>
            <vue-slider
              v-model="salary_range_monthly"
              :enable-cross="false"
              class="w-100 mt-3"
              :contained="true"
              :silent="true"
              :tooltip="'always'"
              :min="min_salary_range"
              :max="max_salary_range"
              :interval="1"
              :tooltip-formatter="salary_formatter"
            /> -->
            <FilterSalary v-if="renderFilter" :salary-range="salary_range" :min-salary-range="min_salary_range"
              :max-salary-range="max_salary_range" @update="(e) => (salary_range = e)" />
          </div>
        </b-modal>

        <b-modal id="modal-lg" ref="filter-employment-modal" v-model="filterModals.employment_type.state" centered
          hide-footer title="Employment Type">
          <div class="mt-1 mb-1">
            <FilterEmploymentType :options="employment_types" :selected-value="selected_employment_type"
              @update="(e) => (selected_employment_type = e)" />
          </div>
        </b-modal>

        <b-modal id="modal-lg" ref="filter-employment-modal" v-model="filterModals.job_function.state" centered
          hide-footer title="Job Function">
          <div class="mt-1 mb-1">
            <FilterJobFunction :options="all_job_functions" :selected-value="selected_job_function"
              @update="(e) => (selected_job_function = e)" />
          </div>
        </b-modal>

        <b-modal id="modal-lg" ref="filter-sort-modal" v-model="filterModals.sort.state" centered hide-footer
          title="Sort by">
          <div class="mt-1 mb-1">
            <h6 class="">By Position</h6>
            <v-select v-model="sort_choice" label="value" class="text-white" :options="sort_options"
              :reduce="(value) => value.value" />
          </div>
        </b-modal>
      </template>
    </div>

    <div v-if="showLayout" class="container-fluid">
      <AppFooter v-if="!kiosk.isKiosk" class="w-100" />
    </div>

    <!-- Scroll to top button -->
    <div v-show="showScrollTop" class="scroll-to-top" @click="scrollToTop">
      <feather-icon icon="ArrowUpIcon" size="24" />
    </div>

    <!-- Kiosk Timeout Modal -->
    <b-modal ref="timeoutModal" v-model="modals.timeout_modal.state" hide-footer>
      <h4>
        Are you still there? <br />
        <br />
        Your session will end automatically in
        <b> {{ modals.timeout_modal.remaining_time }} seconds </b><br />
        Press 'Continue Browsing' to keep your session.
      </h4>
      <div class="mt-3 d-flex flex-row flex-gap-md justify-content-end">
        <button ref="noButtonRef" class="btn btn-outline-danger btn-round font-weight-bolder" @click="kioskTimeoutNo">
          End Session ({{ modals.timeout_modal.remaining_time }})
        </button>
        <button ref="yesButtonRef" class="btn btn-success btn-round" @click="kioskTimeoutYes">
          Continue Browsing
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import SpinnerBtn from "@/layouts/components/spinners/SpinnerButton.vue";
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBTooltip,
  VBModal,
  BBadge,
  BOverlay,
  BAlert,
  BTabs,
  BTab,
} from "bootstrap-vue";

import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import { intersection, orderBy, toInteger, inRange, debounce } from "lodash";
import AppNavbarFull from "@/layouts/components/AppNavbarFull.vue";
import AppFooter from "@/layouts/components/AppFooter.vue";
import KioskFooter from "@/layouts/components/KioskFooter.vue";
import {
  allIndustries,
  specialIndustries,
  jobFunctions,
  jobTypeOptions,
} from "@/constants/datastore";
// import JobPopup from '@/components/JobPopup.vue'
import LoginPage from "@/views/authentication/login/LoginForm.vue";
import RegistrationForm from "@/views/authentication/registration/RegistrationForm.vue";
import { onResizeMixins } from "@/components/mixins/onResizeMixins";
import { listingsMixin } from "./mixins/listingsMixins";

export default {
  components: {
    SpinnerBtn,
    // HelpDesksIssueFloating,

    // ListEvent,
    // JobPopup,
    AppFooter,
    KioskFooter,
    VueSlider,
    vSelect,
    BCard,
    BBadge,
    BAlert,
    BFormGroup,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BOverlay,

    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    AppNavbarFull,

    LoginPage,
    RegistrationForm,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  mixins: [listingsMixin, onResizeMixins],
  props: {
    groupEventId: { type: String, default: "" },
  },

  data() {
    return {
      showLoa: false,
      acceptLoa: false,
      showScrollTop: false,
      companySearchLoading: false,
      industrySearchLoading: false,
      preferred_position: [],
      isLoading: false,
      all_positions: [],
      renderPositionSearch: true,

      all_share_icons: [
        {
          name: ["fab", "facebook-square"],
          styling: "facebook-color",
          network: "facebook",
        },
        {
          name: ["fab", "twitter-square"],
          styling: "twitter-color",
          network: "twitter",
        },
        {
          name: ["fab", "whatsapp-square"],
          styling: "whatsapp-color",
          network: "whatsapp",
        },
        {
          name: ["fab", "linkedin"],
          styling: "linkedin-color",
          network: "linkedin",
        },
        {
          name: ["fab", "facebook-messenger"],
          styling: "messenger-color",
          network: "messenger",
        },
        {
          name: ["fab", "telegram"],
          styling: "telegram-color",
          network: "telegram",
        },
      ],
      book: {
        text: "Register",
        color: "primary",
      },
      select_event_type: "",
      event_types: [
        { text: "Single events", value: "single" },
        { text: "Group events", value: "group" },
      ],
      group_preview_popup: false,
      group_preview_link: "",
      selected_job_type: [],
      sort_choice: "Default",
      sort_options: [
        { text: "Default", value: "Default" },
        { text: "Ascending", value: "Ascending" },
        { text: "Descending", value: "Descending" },
      ],
      selected_industry: [],
      industries: [],

      selected_employment_type: [],
      job_types: jobTypeOptions,
      employment_types: [
        { text: "Internship/Traineeship", value: "internship_traineeship" },
        { text: "Freelance", value: "freelance" },
        { text: "Permanent", value: "permanent" },
        { text: "Contract", value: "contract" },
        { text: "Flexi-work", value: "flexiwork" },
        { text: "Temporary", value: "temporary" },
      ],
      salary_formatter: (v) =>
        `S$${`${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
      selected_location: "",
      selected_event_title: "",
      redirection_link: null,
      all_locations: [
        {
          text: "North",
          value: "North",
        },
        {
          text: "South",
          value: "South",
        },
        {
          text: "Central",
          value: "Central",
        },
        {
          text: "East",
          value: "East",
        },
        {
          text: "West",
          value: "West",
        },
      ],
      all_dates: [],
      specific_company: "",
      // $generateDownloadUrl(`company-profile-image/${specific_company_image}`)
      specific_company_image: "",
      event_id: "",
      fullname: "",
      email_address: "",
      show_inside: false,
      popupActive: false,
      image_url: "default",
      event_exist: false,
      action: "insert",
      current_item: {},
      popupReg: false,
      event_details: null,

      // Employments
      popupEmployments: false,
      // Educations
      popupEducations: false,
      scrollY: 0,
      item_id: "",
      job: {},
      companies: [],
      registered: [],
      events_data: [],
      all_events_data: [],
      select_tags: [],
      select_date: [],
      select_company: [],
      select_job_source: [],
      select_organiser: [],
      organiser_companies: [],
      current_user: "",
      position: "",
      description: "",
      skip_salary_filter: true,
      salary_range: [0, 500000],
      salary_range_monthly: [0, Math.round(500000 / 12)],
      salary_range_yearly: [0, 500000],
      min_salary_range: 0,
      max_salary_range: 500000,
      jobSources: [
        { text: "HeadHunters HQ", value: "HeadHunters HQ" },
        { text: "Others", value: "Others" },
      ],
      categories: [
        { text: "Administration, business and management", value: 1 },
        { text: "Alternative therapies", value: 2 },
        { text: "Animals, land and environment", value: 3 },
        { text: "Computing and ICT", value: 4 },
        { text: "Construction and building", value: 5 },
        { text: "Design, arts and crafts", value: 6 },
        { text: "Education and training", value: 7 },
        { text: "Engineering", value: 8 },
        { text: "Facilities and property services", value: 9 },
        { text: "Financial services", value: 10 },
        { text: "Garage services", value: 11 },
        { text: "Hairdressing and beauty", value: 12 },
        { text: "Healthcare", value: 13 },
        { text: "Heritage, culture and libraries", value: 14 },
        { text: "Hospitality, catering and tourism", value: 15 },
        { text: "Legal and court services", value: 16 },
        { text: "Manufacturing and production", value: 17 },
        { text: "Performing arts and media", value: 18 },
        { text: "Retail and customer services", value: 19 },
        { text: "Science, mathematics and statistics", value: 20 },
        { text: "Security, uniformed and protective services", value: 21 },
        { text: "Social sciences and religion", value: 22 },
        { text: "Sport and leisure", value: 23 },
        { text: "Transport, distribution and logistics", value: 24 },
      ],
      dates: [],
      popup_event: {},
      current_event_id: "",

      filter_popup: false,
      group_event_image: false,

      searchTimer: null,
      selected_status: true,
      all_event_status: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ],
      all_job_functions: [],
      selected_job_function: [],
      total_events: [],
      start_page_count: 0,
      end_page_count: 0,
      current_job_page: 1,
      page_size: 4,
      accountancy_event: false,
      pause_trigger: false,

      load_more_counter: 4,
      expand_icon: "icon-minimize-2",
      expand_search: true,
      companyImage: "",
      appNavbarFullImageUrl: null,
      showLayout: true,

      base_listings: "http://dashboard.headhuntershq.com/listings/",
      // base_listings: 'http://localhost:8082/listings/',
      viewMoreLoading: false,
      searchLoading: false,
      jobsLoading: false,
      mcf_message:
        "All information on jobs is published in good faith. e2i does not make any warranties about the completeness, reliability and accuracy of this information. From the jobs portal, you can visit other websites by following hyperlinks on the job ads to external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove any dead/unrelated/inappropriate content or links.",
      listingsPagination: {
        currentPage: 0,
        skipPages: 0,
        limit: 6,
      },
      activeFilters: {
        search: false,
        sort: false,
        company: false,
        location: false,
        employment_type: false,
        selected_industry: false,
        selected_job_function: false,
        select_date: false,
      },

      showEventButtons: true, // use to rerender buttons after submitting so it can turn to applied button

      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      isFiltered: false,

      firstLoad: true,
      filterTitleClass: "my-1 font-weight-bolder",
    };
  },
  computed: {
    excludeRangeForEFC() {
      const efc_jobs = this.events_data.map(
        (item) => item.source_name === "efc"
      );
      if (!efc_jobs.includes(false)) {
        return true;
      }

      return false;
    },

    eventsGroups() {
      return Array.from(Array(Math.ceil(this.events_data.length / 4)).keys());
    },
    loadedAllJobs() {
      // if (this.load_more_counter < this.all_events_data.length) {
      if (this.$length(this.events_data) >= this.totalRecords) {
        return true;
      }

      return false;
    },
  },
  watch: {
    acceptLoa(newVal, oldVal) {
      this.activateShareFunc({
        name: ['fab', 'whatsapp-square'],
        styling: 'whatsapp-color opacity-25',
        network: 'whatsapp',
      }, this.current_item)
      this.$store.commit("auth/UPDATE_LOA", newVal, { root: true });
    },
    "$route.query": function () {
      localStorage.setItem(
        "listings_filters",
        JSON.stringify(this.$route.query)
      );
    },
    current_job_page: {
      handler(val, oldVal) {
        this.events_data = this.total_events[val - 1];
      },
    },

    selected_event_title: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
        this.$addParamsToUrl("query", val);
        // if (this.$isNotEmpty(val)) {
        //   this.$addParamsToUrl('query', val)
        // } else {
        //   this.$removeParamsToUrl('query')
        // }
      },
    },
    salary_range_monthly: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          if (val[1] !== 0) {
            this.salary_range_yearly = [0, 0];
          }
          this.salary_range = [val[0] * 12, val[1] * 12];
        }
      },
    },
    salary_range_yearly: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          if (val[1] !== 0) {
            this.salary_range_monthly = [0, 0];
          }
          this.salary_range = [val[0], val[1]];
        }
      },
    },
    salary_range: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.searchText();
        }
        this.$addParamsToUrl("filtered_salary", val);
      },
    },
    selected_employment_type: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
        this.$addParamsToUrl("filtered_employment_types", val);
      },
    },
    selected_job_function: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
        this.$addParamsToUrl("filtered_job_functions", val);
      },
    },
    select_tags: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
      },
    },
    select_date: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
        this.$addParamsToUrl("filtered_dates", val);
      },
    },
    select_company: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
        this.$addParamsToUrl("filtered_companies", val);
      },
    },
    select_job_source: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
        this.$addParamsToUrl("filtered_source", val);
      },
    },

    select_organiser: {
      handler(val, oldVal) {
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
        this.$addParamsToUrl("filtered_organisers", val);
      },
    },
    selected_location: {
      handler(val, oldVal) {
        this.$addParamsToUrl("filtered_locations", val);
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
      },
    },
    selected_job_type: {
      handler(val, oldVal) {
        this.$addParamsToUrl("filtered_job_types", val);
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
      },
    },

    selected_industry: {
      handler(val, oldVal) {
        this.$addParamsToUrl("filtered_industries", val);
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
      },
    },

    select_event_type: {
      handler(val, oldVal) {
        this.serverParams.page = 1;
        this.specificGetEvents();
      },
    },

    sort_choice: {
      handler(val, oldVal) {
        this.$addParamsToUrl("sort_position", val);
        if (!this.pause_trigger) {
          this.serverParams.page = 1;
          this.specificGetEvents();
        }
      },
    },
    popup_quiz: {
      handler(val, oldVal) { },
    },
    popupProfileQuestions: {
      handler(val, oldVal) { },
    },

    deep: true,
  },

  mounted() {
    window.addEventListener("scroll", this.debouncedHandleScroll);
    try {
      const next = this.$children[0].$refs.btnclose;
      next.$el.addEventListener("click", this.onClickCloseButton, false);
    } catch (error) { }
  },
  beforeUnmount() {
    // Remove scroll event listener to prevent memory leaks
    window.removeEventListener("scroll", this.debouncedHandleScroll);
  },
  created() {
    localStorage.setItem("listings_filters", JSON.stringify(this.$route.query));

    // repopulate the filters from the URL
    if (!this.$isEmpty(this.$route.query)) {
      this.pause_trigger = true;
      Object.entries(this.$route.query).forEach(([key, value]) => {
        switch (key) {
          case "query":
            this.selected_event_title = value;
            break;
          case "sort_position":
            this.sort_choice = value;
            break;
          case "filtered_companies":
            this.select_company = value;
            break;
          case "filtered_source":
            this.select_job_source = value;
            break;
          case "filtered_locations":
            this.selected_location = value;
            break;
          case "filtered_salary":
            this.salary_range = value;
            break;
          case "filtered_job_types":
            this.selected_job_type = value;
            break;
          case "filtered_employment_types":
            this.selected_employment_type = value;
            break;
          case "filtered_industries":
            this.selected_industry = value;
            break;
          case "filtered_job_functions":
            this.selected_job_function = value;
            break;

          default:
            break;
        }
      });
    }

    if (this.$router.currentRoute.path.includes("/listings")) {
      this.showLayout = true;
    } else if (
      ["kiosk-page", "kiosk-company-page"].includes(this.$route.name)
    ) {
      this.showLayout = true;
      this.$set(this.kiosk, "isKiosk", true);
    } else {
      this.showLayout = false;
    }
    if (window.screen.width < 769) {
      this.expand_search = false;
    }
    this.end_page_count = this.page_size;
    this.current_event_id = this.$route.params.company;
    // this.getUser()
    // const { token } = this.$store.state.auth.ActiveUser
    this.email_address = this.$store.state.auth.ActiveUser
      ? this.$store.state.auth.ActiveUser.email_address
      : null;
    // this.$http.defaults.headers.common["Authorization"] = `Token ${token}`;
    // this.industries = allIndustries

    // this.industries = orderBy(this.industries, ['text'], ['asc'])
    this.all_locations = orderBy(this.all_locations, ["text"], ["asc"]);
    this.employment_types = orderBy(this.employment_types, ["text"], ["asc"]);
    this.job_types = orderBy(this.job_types, ["text"], ["asc"]);

    // typeform
    this.prefIndustryOptions = orderBy(allIndustries, ["value"], ["asc"]);

    this.searchLoading = false;
    this.jobsLoading = false;

    if (this.current_event_id) {
      if (this.current_event_id.includes("_g")) {
        const eventID = this.current_event_id.replace("_g", "");
        localStorage.setItem("track_event_id", eventID);
      }
    } else {
      localStorage.removeItem("track_event_id");
      localStorage.removeItem("track_job_id");
    }

    if (this.groupEventId.length > 2) {
      this.getEvents(this.groupEventId, "group_event");
    }
    this.specificGetEvents();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      // Update scroll position
      this.scrollY = window.scrollY;
      this.loadMoreJobs();
    },
    debouncedHandleScroll: debounce(function () {
      this.showScrollTop = window.scrollY > 300;
      this.handleScroll();
    }, 500),
    searchForCompany: debounce((loading, search, vm) => {
      vm.getCompanyFilterOptions(search);
    }, 1500),
    companySearch(search = "", loading = null) {
      this.searchForCompany(loading, search, this);
    },
    searchForIndustry: debounce((loading, search, vm) => {
      vm.getIndustryFilterOptions(search);
    }, 1500),
    industrySearch(search = "", loading = null) {
      this.searchForIndustry(loading, search, this);
    },
    removeJob(jobID) {
      const shareJobList =
        JSON.parse(localStorage.getItem("share_job_list")) || {};
      delete shareJobList[jobID];
      localStorage.setItem("share_job_list", JSON.stringify(shareJobList));
      this.$store.commit(
        "auth/UPDATE_CART_JOBS",
        Object.keys(shareJobList).length,
        { root: true }
      );
      this.$store.commit("auth/UPDATE_SHARE_JOB_LIST", shareJobList, {
        root: true,
      });
    },
    getLSJobs() {
      const shareJobList =
        JSON.parse(localStorage.getItem("share_job_list")) || null;
      let jobDetails = [];
      if (shareJobList) {
        jobDetails = Object.values(shareJobList);
      }

      return jobDetails;
    },

    updateApplications(val) {
      this.registered = val;
      this.$forceUpdate();
    },
    checkForm(val) {
      this.popup_quiz = val;
    },
    activateTypeForm(val) {
      this.popupProfileQuestions = val;
    },
    getQuestions(val) {
      this.popup_quiz = true;
      this.popup_questions = val.questions;
      // this.save_nric = val.save_nric
      this.save_nric = false;
    },
    checkProfileCompletion() {
      let incomplete = true;
      try {
        incomplete =
          this.$store.state.auth.ActiveUser.profile_status.incomplete;
      } catch (error) {
        incomplete = true;
      }
      return incomplete;
    },
    loadMoreJobs() {
      this.listingsPagination.currentPage += 5;
      this.listingsPagination.skipPages += 5;

      this.serverParams.page += 1;
      this.viewMoreLoading = true;
      this.searchLoading = false;
      this.specificGetEvents();
    },
    cleanLocation(location, zone) {
      let { address } = location;
      const { country } = location;
      address = !this.$isEmpty(address)
        ? address.replace(`${country} `, "")
        : address;

      let currentZone = zone;
      if (
        location.postal_code === "000000" ||
        location.postal_code === "999999"
      ) {
        currentZone = "Islandwide";
      } else if (currentZone === undefined || currentZone === null) {
        currentZone = "NA";
      }
      let fullLocation = `${currentZone} (${location.country}${this.$isEmpty(location.country)
        ? location.postal_code
        : ` ${location.postal_code}`
        })`
        .replace("999999", "")
        .replace(" null", "")
        .replace("null", "");
      fullLocation = fullLocation.replace("()", "");
      return fullLocation;
      // return `${currentZone} (${location.country} ${location.postal_code})`.replace(' null', '').replace('null', '')
    },
    partialReg() {
      this.$bvModal
        .msgBoxConfirm(
          "Currently, your application isn't viewable by the employer since you haven't completed your profile." +
          " \n\nClicking the button below will redirect you to your profile page where you can fill up the required details.",
          {
            title: "Incomplete Profile",
            size: "lg",
            okVariant: "warning",
            okTitle: "My Profile",
            cancelTitle: "Go Back",
            cancelVariant: "primary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            window.location.href = "/profile";
          }
        });
    },

    resetFilters(redirect = true) {
      localStorage.removeItem("listings_filters");
      this.activeFilters = {
        search: false,
        sort: false,
        company: false,
        location: false,
        employment_type: false,
        selected_industry: false,
        selected_job_function: false,
        select_date: false,
      };
      this.selected_event_title = "";
      this.pause_trigger = true;
      this.sort_choice = "Default";
      this.select_company = [];
      this.select_job_source = [];
      this.select_organiser = [];
      this.selected_location = "";
      this.skip_salary_filter = true;
      // this.salary_range = [0, 500000]
      this.salary_range = [this.min_salary_range, this.max_salary_range];
      this.selected_job_type = [];
      this.selected_employment_type = [];
      this.selected_industry = [];
      this.selected_job_function = [];
      this.select_date = [];
      this.specificGetEvents();
      this.renderFilter = false;
      setTimeout(() => {
        this.renderFilter = true;
      }, 1);

      if (redirect) {
        if (this.$isNotEmpty(this.kiosk) && this.kiosk.isKiosk) {
          this.$router.push({ name: "kiosk-page", query: {} });
        } else {
          this.$router.push({ name: "listings-page", query: {} });
        }
      }
    },
    resetFilterAndRedirect(routeName, params = null) {
      this.$router.push({ name: routeName, params, query: {} });
    },
    grouped() {
      const { company } = this.$route.params;
      return company;
    },
    fireRedirect(item) {
      window.open(item.redirection_url, "_blank");
    },

    checkRedirection() {
      if (this.redirection_link) {
        window.open(this.redirection_link, "_blank");
      }
    },

    specificGetEvents() {
      if (!this.viewMoreLoading) {
        this.jobsLoading = true;
      }
      this.searchLoading = true;
      this.load_more_counter = 4;
      if (this.$route.params.company !== undefined) {
        this.specific_company = this.$route.params.company;

        if (this.$route.params.company.endsWith("_g")) {
          const eventId = this.$route.params.company.replace("_g", "");
          if (
            eventId === "61de74bc21c14eb016e518cb" ||
            eventId === "e88e4812-82c8-4d49-8aa1-0b3e3129bb43"
          ) {
            this.industries = specialIndustries;
          } else if (
            eventId === "61dcd0dc62adac05c99714c4" ||
            eventId === "ed14fa6a-0dc1-4fa2-a6ca-53d5daad5d2c"
          ) {
            this.accountancy_event = true;
            this.$emit("accountancy-event", this.accountancy_event);
          }
          this.getEvents(eventId, "group_event");
        } else {
          const eventId = this.$route.params.company;
          if (
            this.$route.params.company === "61de74bc21c14eb016e518cb" ||
            eventId === "e88e4812-82c8-4d49-8aa1-0b3e3129bb43"
          ) {
            this.industries = specialIndustries;
          } else if (
            eventId === "61dcd0dc62adac05c99714c4" ||
            eventId === "ed14fa6a-0dc1-4fa2-a6ca-53d5daad5d2c"
          ) {
            this.accountancy_event = true;
            this.$emit("accountancy-event", this.accountancy_event);
          }
          this.getEvents(this.$route.params.company, "employer");
        }
      } else {
        this.getEvents();
      }
    },
    cleanCycles(dirtyCycle) {
      const equivCycles = {
        yearly: "per Year",
        monthly: "per Month",
        weekly: "per Week",
        daily: "per Day",
        hourly: "per Hour",
      };

      const cleanedCycle = !this.$isEmpty(dirtyCycle)
        ? equivCycles[dirtyCycle.toLowerCase()]
        : "";

      return cleanedCycle;
    },
    searchText() {
      clearTimeout(this.searchTimer);

      this.searchTimer = setTimeout(() => {
        this.specificGetEvents();
      }, 1000);
    },
    shareEvent(item) {
      this.current_item = item;
      this.share_link = this.getLink(item.id);
      this.$refs["share-job-modal"].toggle("#toggle-btn");
      this.share_popup = true;
    },

    shareKioskJob(item) {
      this.current_item = item;
      this.share_link = this.getKioskLink(item.id);
      this.modals.share_media_selection.phone_number = null;
      this.modals.share_media_selection.state = true;
      this.modals.share_media_selection.selected_media = null;
      this.email_input = null;
      this.show_qr_code = false;
      this.show_email_input = false;
      this.$refs["share-kiosk-job-modal"].toggle("#toggle-btn");
      this.share_popup = true;
    },

    getEventType(value) {
      if (value === "virtual") {
        return "Virtual Interview";
      }
      if (value === "physical") {
        return "In-Person Interview";
      }
      if (value === "speed_interview") {
        return "Virtual Speed Interview";
      }
      return "NA";
    },
    goToEvents(eventId) {
      this.event_id = eventId;

      localStorage.setItem("current_event", eventId);
      // let route = this.$router.resolve({ path: "/events/" + event_id });
      //   this.group_preview_link = route.href
      this.group_preview_popup = true;
      // let route = this.$router.resolve({ path: "/events/" + event_id });

      // window.open(route.href, "_blank");
    },

    onCopy(eventId) {
      this.copyTextToClipboard(`${window.location.origin}/listing/${eventId}`);
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Job Link Alert",
            icon: "AlertCircleIcon",
            text: "Job link copied successfully",
            variant: "success",
          },
        },
        { timeout: this.$longestTimeout }
      );
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        () => { },
        (err) => { }
      );
    },

    bookEvent() {
      if (this.book.text === "Applied") {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Job Application Update",
              icon: "AlertCircleIcon",
              text: "You have already applied for this job.",
              variant: "danger",
            },
          },
          { timeout: this.$longestTimeout }
        );
      } else if (this.email_address.length > 4 && this.fullname.length > 3) {
        const all_data = {
          event_id: this.event_id,
          email_address: this.email_address,
          fullname: this.fullname,
          approved: false,
          interviewed: false,
          company: this.$store.state.auth.ActiveUser.company_name,
        };
        this.$http
          .post("/api/book", { data: all_data })
          .then((response) => {
            const { success } = response.data;
            if (success) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Registration Update",
                    icon: "AlertCircleIcon",
                    text: response.data.message,
                    variant: "success",
                  },
                },
                { timeout: this.$longestTimeout }
              );
              // this.book.text = "Registered";
              // this.book.color = "success";
              this.popupReg = false;
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: "Registration Update",
                    icon: "AlertCircleIcon",
                    text: response.data.message,
                    variant: "danger",
                  },
                },
                { timeout: this.$longestTimeout }
              );
            }
          })
          .catch((error) => { });
      } else {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Invalid Data",
              icon: "AlertCircleIcon",
              text: "Please enter a valid email address or full name",
              variant: "danger",
            },
          },
          { timeout: this.$longestTimeout }
        );
      }
    },
    viewMore(item) {
      localStorage.setItem("current_event", item.id);
      this.show_inside = true;
      this.job = item;
      this.popupActive = true;
    },
    trimMyString(string) {
      const start = 0;
      const maxLength = 200;
      // Note - `start` is if I want to start after some point of the string
      if (string.length > maxLength) {
        const trimmedString = string.substr(start, maxLength);
        return `${trimmedString.substr(
          start,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
        )} ......`;
      }
      return string;
    },
    filter_events(data) {
      // this.$vs.loading({ container: "#div-with-loading", scale: 0.6 });

      let new_events = false;
      const min_salary = toInteger(data.min_salary);
      const max_salary = toInteger(data.salary);
      const salary_cycle = data.salary_cycle.toLowerCase();
      let multiplier = 1;

      if (salary_cycle === "yearly") {
        multiplier = 1;
      } else if (salary_cycle === "weekly") {
        multiplier = 52;
      }
      const salary =
        inRange(
          min_salary * multiplier,
          this.salary_range[0],
          this.salary_range[1]
        ) ||
        inRange(
          max_salary * multiplier,
          this.salary_range[0],
          this.salary_range[1]
        );

      const comp =
        this.select_company.length > 0
          ? this.select_company.includes(data.company)
          : true;

      const source =
        this.select_job_source.length > 0
          ? this.select_job_source.includes(data.source)
          : true;

      const organiser =
        this.select_organiser.length > 0
          ? this.select_organiser.includes(data.organiser)
          : true;
      const location =
        this.selected_location.length > 0
          ? this.selected_location.includes(data.location_zone)
          : true;

      // let status = this.selected_status.length > 0 ? this.selected_status : true;
      const similar_industry = intersection(this.selected_industry, data.tags);

      const tags =
        this.selected_industry.length > 0 ? similar_industry.length > 0 : true;

      const event_type =
        this.select_event_type.length > 0
          ? this.select_event_type.includes(data.event_category)
          : true;

      const similar_job_functions = intersection(
        this.selected_job_function,
        data.job_functions
      );
      const job_function =
        this.selected_job_function.length > 0
          ? similar_job_functions.length > 0
          : true;

      const employment_type =
        this.selected_employment_type.length > 0
          ? this.selected_employment_type.some((v) =>
            data.employment_type.includes(v)
          )
          : true;

      const job_type =
        this.selected_job_type.length > 0
          ? this.selected_job_type.includes(data.job_type)
          : true;

      const date =
        this.select_date.length > 0
          ? this.select_date.includes(data.start_date)
          : true;

      const title =
        this.selected_event_title.length > 0
          ? data.position
            .toLowerCase()
            .includes(this.selected_event_title.toLowerCase())
          : true;

      if (
        comp &&
        source &&
        organiser &&
        tags &&
        location &&
        event_type &&
        job_type &&
        date &&
        title &&
        job_function &&
        employment_type &&
        salary
      ) {
        new_events = true;
      }

      return new_events;
      // this.events_data = new_events;
      // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
    },

    smartFilter(data) {
      const { available_job_functions } = data;
      const { available_job_types } = data;
      const { available_industries } = data;
      const { available_employment_types } = data;

      if (available_job_functions.length > 0) {
        this.all_job_functions = available_job_functions.map((industry) => ({
          text: industry,
          value: industry,
        }));
      } else {
        this.all_job_functions = [];
      }

      if (available_job_types.length > 0) {
        this.job_types = this.job_types.filter((jobType) =>
          jobType.value.length > 0
            ? available_job_types.includes(jobType.value)
            : true
        );
      } else {
        // this.job_types = []
      }

      if (available_industries.length > 0) {
        this.industries = available_industries.map((industry) => ({
          text: industry,
          value: industry,
        }));
      } else {
        this.industries = [];
      }

      if (available_employment_types.length > 0) {
        this.employment_types = this.employment_types.filter((empTypes) =>
          available_employment_types.includes(empTypes.value)
        );
      } else {
        this.employment_types = [];
      }
    },
    firstSelection(field) {
      if (!this.activeFilters[field]) {
        this.events_data = [];
        this.all_events_data = [];
        this.registered = {};
        this.listingsPagination.skipPages = 0;
      }

      this.activeFilters[field] = true;
    },
    async getEvents(companyFilter, special = "") {
      const { email_address } = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser
        : { email_address: null };

      this.current_user = email_address;

      const companyName = this.$route.params.company_name;

      const all_event_group = [];

      function attachSymbol(text) {
        return text.length > 0 ? "&" : "?";
      }

      this.serverParams.columnFilters = {};

      if (special === "group_event") {
        this.serverParams.columnFilters.group_event = companyFilter;
      }

      if (this.sort_choice !== "Default") {
        this.serverParams.columnFilters.sort_position = this.sort_choice;
        this.firstSelection("sort");
        // this.serverParams.columnFilters.sort_position = this.sort_choice
      }

      if (companyName) {
        this.serverParams.columnFilters.company_name = companyName;
      }
      if (this.$length(this.selected_event_title) > 0) {
        this.serverParams.columnFilters.query = this.selected_event_title;
        this.firstSelection("search");
      }

      if (this.$length(this.select_company) > 0) {
        this.serverParams.columnFilters.filtered_companies =
          this.select_company;
        this.firstSelection("employer");
      }

      if (this.$length(this.select_job_source) > 0) {
        this.serverParams.columnFilters.filtered_source =
          this.select_job_source;
        this.firstSelection("source");
      }

      if (this.$length(this.select_organiser) > 0) {
        this.serverParams.columnFilters.filtered_organisers =
          this.select_organiser;
      }

      if (this.$length(this.selected_location) > 0) {
        this.serverParams.columnFilters.filtered_locations =
          this.selected_location;
        this.firstSelection("location");
      }

      if (this.$length(this.selected_employment_type) > 0) {
        this.serverParams.columnFilters.filtered_employment_types =
          this.selected_employment_type;
        this.firstSelection("employment_type");
      }

      if (this.$length(this.selected_industry) > 0) {
        this.serverParams.columnFilters.filtered_industries =
          this.selected_industry;
        this.firstSelection("selected_industry");
      }

      if (this.$length(this.selected_job_function) > 0) {
        this.serverParams.columnFilters.filtered_job_functions =
          this.selected_job_function;
        this.firstSelection("selected_job_function");
      }

      if (this.$length(this.select_date) > 0) {
        this.serverParams.columnFilters.filtered_dates = this.select_date;
        this.firstSelection("select_date");
      }

      if (this.$length(this.salary_range) > 0) {
        // in order to load jobs with no min / max salary specified
        if (
          (parseInt(this.min_salary_range, 0) === 0 &&
            parseInt(this.max_salary_range, 0) === 500000) || // this is for the firs init load
          (this.salary_range[0] === this.min_salary_range &&
            this.salary_range[1] === this.max_salary_range) // if user did not change the min / max salary range
        ) {
          this.skip_salary_filter = true;
          this.serverParams.columnFilters.filtered_salary = [0, 999999999];
        } else {
          this.skip_salary_filter = false;
          this.serverParams.columnFilters.filtered_salary = this.salary_range;
        }
      }
      if (this.select_date.length > 0) {
        this.serverParams.columnFilters.filtered_dates = this.select_date;
      }

      if (this.selected_job_type.length > 0) {
        this.serverParams.columnFilters.filtered_job_types =
          this.selected_job_type;
        this.firstSelection("selected_industry");
      }

      let query = "";
      if (this.serverParams.page === 1) {
        this.events_data = [];
        this.all_events_data = [];
        this.registered = {};
      }

      this.isFiltered = this.checkIsFiltered();
      if (this.isFiltered) {
        this.serverParams.featured_job_ids = [];
      }

      this.getListingsFilterOptions(companyFilter, special);

      query = `?params=${encodeURIComponent(
        JSON.stringify({ ...this.serverParams, isFiltered: this.isFiltered })
      )}`;

      // query = `?params=${JSON.stringify({ ...this.serverParams })}`
      this.isLoading = true;
      // this.$toastPrimary("", "Loading jobs...")
      const response = await this.$http.post(`/api/all-events${query}`, {
        filter_data: {
          fetched_job_ids: this.fetchedJobIds,
        },
      });
      if (response.data.success) {
        this.isLoading = false;
        this.event_details = response.data.event_details;
        this.fetchedJobIds = response.data.fetched_job_ids;

        this.event_exist = true;
        this.registered = {
          ...this.registered,
          ...response.data.registered,
        };

        // this.companies = response.data.companies
        // this.organiser_companies = response.data.organisers

        // this.companies = orderBy(this.companies, ['text'], ['asc'])
        // this.organiser_companies = orderBy(this.organiser_companies, ['text'], ['asc'])

        // this.min_salary_range = Math.round(response.data.min_max_salary[0] * 12)
        // this.max_salary_range = Math.round(response.data.min_max_salary[1] * 12)
        // this.all_locations = response.data.locations
        // const jobFunctions = response.data.available_job_functions.map(item => ({ text: item, value: item }))

        // this.all_job_functions = orderBy(jobFunctions, ['text'], ['asc'])
        // this.all_dates = response.data.all_dates
        // this.all_dates = orderBy(this.all_dates, ['text'], ['asc'])
        // this.smartFilter(response.data)

        // eslint-disable-next-line no-restricted-syntax
        for (const item of response.data.message) {
          const output = item;
          if (!this.events_data.some(event => event.id === item.id)) {
              this.events_data.push(item);
          }
          // this.events_data.push(item);
          // if (this.$route.params.company) {
          //   this.companyImage = item.company_image
          //   this.$emit('image-update', item.organiser_image)
          //   this.group_event_image = await this.$generateDownloadUrl(item.event_banner)
          // }
        }
        this.totalRecords = response.data.total_records;
        this.serverParams.featured_job_ids = response.data.featured_job_ids;
        this.getEventFilesUrl();
        this.updateMetaData();
      } else {
        this.isLoading = false;
        this.event_exist = false;
        // this.searchLoading = false
      }
      this.pause_trigger = false;
      this.jobsLoading = false;
      this.viewMoreLoading = false;

      // this.searchLoading = false
      // .then(response => {

      // })
      // .catch(error => {
      //   this.$handleErrorResponse(error)
      //   this.pause_trigger = false
      //   this.jobsLoading = false
      //   this.searchLoading = false
      // })

      // this.$http
      //   .get(`/api/top-events`)
      //   .then(response => {
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     this.$handleErrorResponse(error)
      //   })
      // this.$http
      //   .get(`/api/top-jobs`)
      //   .then(response => {
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     this.$handleErrorResponse(error)
      //   })
    },
    async getCompanyFilterOptions(companyName) {
      this.companySearchLoading = true;
      let query = "";
      this.serverParams.companyNameFilter = true;
      this.serverParams.companyNameSearch = companyName;
      query = `?params=${encodeURIComponent(
        JSON.stringify(this.serverParams)
      )}`;

      const response = await this.$http.get(
        `/api/all-events-filter-options${query}`
      );
      if (response.data.success) {
        this.companies = response.data.companies;
        this.companies = orderBy(this.companies, ["text"], ["asc"]);
      }
      // this.pause_trigger = false
      // this.jobsLoading = false
      this.companySearchLoading = false;
    },
    async getIndustryFilterOptions(industryName) {
      this.industrySearchLoading = true;
      let query = "";
      this.serverParams.industryNameFilter = true;
      this.serverParams.industryNameSearch = industryName;
      query = `?params=${encodeURIComponent(
        JSON.stringify(this.serverParams)
      )}`;

      const response = await this.$http.get(
        `/api/all-events-filter-options${query}`
      );
      if (response.data.success) {
        const { available_industries } = response.data;
        if (available_industries.length > 0) {
          this.industries = available_industries.map((industry) => ({
            text: industry,
            value: industry,
          }));
        } else {
          this.industries = [];
        }
        this.industries = orderBy(this.industries, ["text"], ["asc"]);
      }
      // this.pause_trigger = false
      // this.jobsLoading = false
      this.industrySearchLoading = false;
    },
    async getListingsFilterOptions(companyFilter, special = "") {
      const companyName = this.$route.params.company_name;

      let query = "";
      query = `?params=${encodeURIComponent(
        JSON.stringify(this.serverParams)
      )}`;

      const response = await this.$http.get(
        `/api/all-events-filter-options${query}`
      );

      if (response.data.success) {
        this.event_exist = true;

        // this.companies = response.data.companies
        // this.organiser_companies = response.data.organisers

        // this.companies = orderBy(this.companies, ['text'], ['asc'])
        // this.organiser_companies = orderBy(this.organiser_companies, ['text'], ['asc'])

        // this.min_salary_range = Math.round(response.data.min_max_salary[0] * 12)
        // this.max_salary_range = Math.round(response.data.min_max_salary[1] * 12)
        this.min_salary_range = response.data.min_max_salary[0];
        this.max_salary_range = response.data.min_max_salary[1];
        if (this.firstLoad) {
          setTimeout(() => {
            this.pause_trigger = true;
            if (!this.firstLoad) {
              this.salary_range = [
                this.min_salary_range,
                this.max_salary_range,
              ];
            }
          }, 100);
          setTimeout(() => {
            this.pause_trigger = false;
            this.firstLoad = false;
          }, 200);
        }

        this.all_locations = response.data.locations;
        const jobFunctions = response.data.available_job_functions.map(
          (item) => ({ text: item, value: item })
        );
        this.all_job_functions = orderBy(jobFunctions, ["text"], ["asc"]);
        this.all_dates = response.data.all_dates;
        this.all_dates = orderBy(this.all_dates, ["text"], ["asc"]);
        this.smartFilter(response.data);
      } else {
        this.event_exist = false;
        this.searchLoading = false;
      }
      this.pause_trigger = false;
      // this.jobsLoading = false
      this.searchLoading = false;
    },
    updateFavicon(iconLink) {
      // Create a new link element for the favicon
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.href = iconLink;
      newFavicon.type = "image/x-icon";

      // Find the existing favicon link element and remove it
      const existingFavicon = document.querySelector('link[rel="icon"]');
      if (existingFavicon) {
        document.head.removeChild(existingFavicon);
      }

      // Add the new favicon link element to the document head
      document.head.appendChild(newFavicon);
    },
    async updateMetaData() {
      if (this.$route.params.company) {
        // console.log(this.events_data)
        // if (this.$isNotEmpty(event_data.organiser_image)) {
        //   const organiser_image_url = await this.$generateDownloadUrl(event_data.organiser_image)
        //   this.updateFavicon(organiser_image_url)
        // }

        document.title = this.event_details.event_name;
      }
    },
    async getEventFilesUrl() {
      if (this.$route.params.company) {
        this.companyImage = this.event_details.company_image;
        this.appNavbarFullImageUrl = this.event_details.organiser_image;
        // this.refs['refAppNavbarFull'].$emit('image-update', organiser_image_url)
        this.group_event_image = this.event_details.event_banner;
      }
      this.events_data.forEach(async (event) => {
        if (this.$isEmpty(event.company_loa_file_url)) {
          event.company_loa_file_url = await this.$generateDownloadUrl(
            event.company_loa_file
          );
        }
        if (this.$isEmpty(event.organiser_loa_file_url)) {
          event.organiser_loa_file_url = await this.$generateDownloadUrl(
            event.organiser_loa_file
          );
        }
      });
    },

    checkIsFiltered() {
      this.isFiltered = false;

      const filters = this.serverParams.columnFilters;
      if (this.$isNotEmpty(filters.filtered_companies)) {
        this.isFiltered = true;
      } else if (this.$isNotEmpty(filters.filtered_locations)) {
        this.isFiltered = true;
      } else if (this.$isNotEmpty(filters.filtered_job_types)) {
        this.isFiltered = true;
      } else if (this.$isNotEmpty(filters.filtered_employment_types)) {
        this.isFiltered = true;
      } else if (this.$isNotEmpty(filters.filtered_industries)) {
        this.isFiltered = true;
      } else if (this.$isNotEmpty(filters.filtered_job_functions)) {
        this.isFiltered = true;
      } else if (this.$isNotEmpty(filters.filtered_salary)) {
        if (
          (Number(this.min_salary_range) === 0 &&
            Number(this.max_salary_range) === 500000) ||
          (Number(this.salary_range[0]) === this.min_salary_range &&
            Number(this.salary_range[1]) === this.max_salary_range) ||
          (Number(filters.filtered_salary[0]) === 0 &&
            Number(filters.filtered_salary[1]) === 999999999) ||
          (Number(filters.filtered_salary[0]) === 0 &&
            Number(filters.filtered_salary[1]) === 500000)
        ) {
          this.isFiltered = false;
        } else {
          this.isFiltered = true;
        }
      }

      return this.isFiltered;
    },

    extract_values() {
      const selected_tags = [];
      for (const data of this.categories) {
        for (const categ of this.select_tags) {
          if (data.value === categ) {
            selected_tags.push(data.text);
          }
        }
      }
      let selected_date = "";
      for (const data of this.dates) {
        if (data.value === this.select_date) {
          selected_date = data.text;
        }
      }

      const all_data = {
        position: this.position,
        description: this.description,
        date: selected_date,
        tags: selected_tags,
        image_url: this.image_url,
      };
      return all_data;
    },

    onClickCloseButton() {
      this.$emit("closePopup");
    },

    cleanerIntervals(start_date, end_date) {
      if (start_date === end_date) {
        return `happening on ${start_date}`;
      }
      return `happening from ${start_date} to ${end_date}`;
    },

    checkIDRegistered(registered, job_id) {
      const id = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser.id
        : null;
      if (registered) {
        if (Object.keys(registered).includes(job_id)) {
          return registered[job_id].includes(id);
        }
        return false;
      }
      return false;
    },
    $addParamsToUrl(keyName, value) {
      if (this.$isNotEmpty(value)) {
        this.$router.push({
          path: this.$route.fullPath,
          query: { [keyName]: value },
        });
      }
    },
    $removeParamsToUrl(keyName) {
      switch (keyName) {
        case "query":
          this.selected_event_title = "";
          this.$replaceUrl(this, this.$route.name, this.$route.params, {
            query: null,
          });
          // this.$set(this.activeFilters, 'search', false)
          break;

        default:
          break;
      }

      const newQuery = this.$route.query;
      delete newQuery[keyName];
      this.$router.push({ path: this.$route.fullPath, query: newQuery });
      // this.specificGetEvents()
    },
    $removeAllParamsToUrl() {
      this.$router.push({ path: this.$route.fullPath });
    },
    jobPositionSearch(search, loading) {
      if (search) {
        if (loading) {
          loading(true);
        }

        this.searchForJob(loading, search, this);
      }
    },
    searchForJob: debounce((loading, search, vm) => {
      vm.positionSearch(search, loading);
    }, 1000),

    positionSearch(query, loading = null) {
      this.$set(this, "all_positions", []);

      const eventId = this.$route.params.company
        ? this.$route.params.company.replace("_g", "")
        : "nil";
      this.$http
        .get(`/api/position-search/${query}/${eventId}`)
        .then((response) => {
          const record = response.data;

          if (record.success) {
            this.$set(this, "all_positions", record.data);

            // record.data.map(data => {
            //   this.all_positions.push(data)
            // })
          }

          if (this.$isNotEmpty(query)) {
            if (record.data.length > 0 && !this.all_positions.includes(query)) {
              this.all_positions.unshift(query);
            }
          }

          this.$set(this, "renderPositionSearch", false);
          setTimeout(() => {
            this.$set(this, "renderPositionSearch", true);
            setTimeout(() => {
              document
                .querySelector("#vSelectJobPosition input.vs__search")
                .focus();
            }, 2);
            setTimeout(() => {
              document.querySelector(
                "#vSelectJobPosition input.vs__search"
              ).value = query;
            }, 3);
          }, 1);

          if (loading) {
            loading(false);
          }
        })
        .catch((error) => {
          this.$handleErrorResponse(error);
        });
    },

    isDefaultSalaryRange() {
      let isDefault = true;
      if (
        !(
          this.salary_range[0] == this.min_salary_range &&
          this.salary_range[1] == this.max_salary_range
        ) &&
        !(this.salary_range[0] == 0 && this.salary_range[1] == 500000)
      ) {
        isDefault = false;
      }
      return isDefault;
    },

    goBack() {
      let href = null;

      if (this.kiosk.isKiosk) {
        href = this.$router.resolve({
          name: "kiosk-company-page",
          query: {},
        }).href;
      } else {
        href = this.$router.resolve({ name: "listings-page", query: {} }).href;
      }

      window.location.href = href;
    },
  },
};
</script>

<style lang="scss">
.opacity-25 {
  opacity: 0.25;
}

.opacity-100 {
  opacity: 1;
}

.job-card {
  .mt-1 {
    margin-top: 0.5rem !important;
  }
}

.v-select input {
  // cursor: pointer;
}

// div {
//   white-space: pre-wrap;
// }

.sticky-element {
  position: -webkit-sticky !important;
  /* Safari */
  position: static !important;
  top: 0;
}

.vue-slider-process {
  background-color: #f37044 !important;
}

.vue-slider-dot-tooltip-text {
  border-color: #f37044 !important;
  background-color: #f37044 !important;
}

.vue-slider-dot-tooltip-inner {
  border-color: #f37044 !important;
  background-color: #f37044 !important;
}

.image-size {
  @media (max-width: 800px) {
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
  }

  @media (min-width: 801px) {
    max-width: 70%;
    max-height: 70%;
    object-fit: scale-down;
  }
}

hr.solid {
  border-top: 3px solid #bbb;
}

.divider {
  @media (min-width: 800px) {
    width: 1px;
    background-color: rgba(94, 91, 91, 0.11);
    margin-left: 0px;
    margin-right: 0px;
    height: 5cm;
  }
}

.icon-header {
  margin-left: 5px;
  margin-top: -1px;
}

.typeform-question-container {
  min-height: 30vh;
  max-height: 80vh;
}

hr {
  margin-top: 1rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

#sidebarFilters {
  position: sticky;
  top: 20px;
  /* Adjust this value based on your navbar + search section height */
  height: 250rem;
  max-height: calc(100vh - 105px);
  /* This ensures it doesn't overflow the viewport */
  overflow-y: auto;
  /* Allows scrolling if the filters are too long */
}

.sticky-search-section {
  position: sticky;
  top: 64px;
  /* Adjust this value based on your navbar height */
  z-index: 1030;
  background-color: white;
  padding: 1rem;
  margin: -1rem;
  width: 100%;
}

.sticky-navbar {
  position: sticky;
  top: 0;
  z-index: 1040;
  width: 100%;
}

.scroll-to-top {
  position: fixed;
  bottom: 100px;
  right: 20px;
  background-color: var(--primary);
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 1000;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  }
}

@import "@core/scss/vue/libs/vue-select.scss";

.filter-button-container {
  margin: 1rem;
  width: auto;
  overflow-x: scroll;
  // -webkit-overflow-scrolling: touch;

  .filter-button-box {
    margin-bottom: 6px;
    display: flex;

    -webkit-overflow-scrolling: touch;

    .btn-icon {
      white-space: nowrap;
      margin: 0 0.5em;
    }

    .btn-icon:first-of-type {
      margin: 0 0.5em 0 0;
    }

    .btn-icon:last-of-type {
      margin: 0 0 0 0.5em;
    }
  }
}
</style>
