/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { isUserLoggedIn } from '@/auth/utils'
import { orderBy } from 'lodash'
import {
  allIndustries,
  highestQualifications,
  genderOptions,
  employmentStatusOptions,
  citizenshipOptions,
  allAvailabilityOptions,
  prefWorkLocationOptions,
} from '@/constants/datastore'
import { singleUpload, deleteObjectByKey } from '@/components/awsS3'
import { v4 as uuidv4 } from 'uuid'
// define a mixin object
export const listingsMixin = {
  components: {
    // * FILTERS
    FilterJobSource: () => import('@/views/event-management-system/filters/FilterJobSource.vue'),
    FilterEmployer: () => import('@/views/event-management-system/filters/FilterEmployer.vue'),
    FilterWorkLocation: () => import('@/views/event-management-system/filters/FilterWorkLocation.vue'),
    FilterSalary: () => import('@/views/event-management-system/filters/FilterSalary.vue'),
    FilterJobType: () => import('@/views/event-management-system/filters/FilterJobType.vue'),
    FilterEmploymentType: () => import('@/views/event-management-system/filters/FilterEmploymentType.vue'),
    FilterJobFunction: () => import('@/views/event-management-system/filters/FilterJobFunction.vue'),
    FilterEmployerIndustry: () => import('@/views/event-management-system/filters/FilterEmployerIndustry.vue'),

    // * SHARE JOB
    ShareJobEmail: () => import('@/views/event-management-system/components/kiosk/ShareJobEmail.vue'),
    KioskShareJobButton: () => import('@/views/event-management-system/components/kiosk/KioskShareJobButton.vue'),
  },

  data() {
    return {
      inactivityTimer: null,
      show_qr_code: false,
      show_email_input: false,
      email_input: '',
      qr_code_info: {},
      qr_code_value: null,
      qr_code_job_details: {},
      share_link: '',
      share_popup: false,

      all_kiosk_share_icons: [

        {
          name: ['fab', 'whatsapp-square'],
          styling: 'whatsapp-color opacity-25',
          network: 'whatsapp',
        },
        {
          name: ['fab', 'telegram'],
          styling: 'telegram-color opacity-25',
          network: 'telegram',
        },
        {
          name: ['fas', 'envelope-open-text'],
          styling: 'email-color opacity-25',
          network: 'email',
        },
      ],

      popup_event: {},
      popup_quiz: false,
      save_nric: false,
      popup_questions: [],
      isE2iLoaAccepted: false,
      isCompanyLoaAccepted: false,

      user: {
        availability: {},
        two_factor: false,
      },
      popupProfileQuestions: false,
      currentProfileQuestion: '', // which question to show, also to keep track next and previous button
      profileQuestionList: [], // store name of field needed to ask
      uploadText: 'Upload My Resume',
      uploadLoading: false,

      // profile completion modal
      normal: true,
      // options
      genderOptions: [],
      employmentStatusOptions: [],
      citizenshipOptions: [],
      allAvailabilityOptions: [],
      prefWorkLocationOptions: [],
      prefIndustryOptions: [],
      preferred_position: [],
      registered: [],

      employmentInfo: [],
      educationInfo: [],

      document_type: 'pdf',
      file_source: '',
      isLoading: false,
      resume_choice: 'existing-resume',
      resume_file: null,
      highestQualifications: [],

      applicationId: null, // send job_id to backend when resume is uploaded to keep track of resume_file

      pageType: 'general',
      kiosk: {
        companyName: 'e2i', // sync it with kiosk.logos
        isKiosk: false,
        showLayout: false,
        logos: {
          e2i: require('@/assets/images/logo/e2i/e2i_Logo2White_updated.png'),
          hhq: require('@/assets/images/logo/e2i/e2i_Logo2White_updated.png'),
        },
      },

      filterModals: {
        sort: { state: false },
        job_source: { state: false },
        employer_company_name: { state: false },
        work_location: { state: false },
        salary: { state: false },
        job_type: { state: false },
        employment_type: { state: false },
        employer_industry: { state: false },
        job_function: { state: false },
      },
      modals: {
        share_media_selection: {
          state: false,
          phone_number: null,
          selected_media: null,
        },
        timeout_modal: {
          state: false,
          remaining_time: 35,
          default_remaining_time: 35,
        },
      },
      renderFilter: true,
    }
  },
  mounted() {
    if (this.kiosk.isKiosk) {
      this.startInactivityTimer()
    }
  },
  created() {
    if (['company-listings-page', 'kiosk-company-page'].includes(this.$route.name)) {
      this.pageType = 'company'
    } else if (['page-event', 'kiosk-page-event'].includes(this.$route.name)) {
      this.pageType = 'event'
    }

    this.genderOptions = genderOptions
    this.employmentStatusOptions = employmentStatusOptions
    this.citizenshipOptions = citizenshipOptions
    this.allAvailabilityOptions = allAvailabilityOptions
    this.prefWorkLocationOptions = prefWorkLocationOptions
    // typeform
    this.prefIndustryOptions = orderBy(allIndustries, ['value'], ['asc'])
    this.highestQualifications = orderBy(highestQualifications, ['value'], ['asc'])
    // this.popupProfileQuestions = this.activatePopup
  },
  methods: {
    formatPositions(position) {
      const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || null
      if (shareJobList) {
        const positions = Object.values(shareJobList).map(item => item.position).join(' | ')
        return positions
      }
      return position
    },
    startInactivityTimer() {
      if (this.kiosk.isKiosk) {
        this.resetInactivityTimer()

        window.addEventListener('mousemove', this.resetInactivityTimer)
        window.addEventListener('keydown', this.resetInactivityTimer)
        window.addEventListener('scroll', this.resetInactivityTimer)
      }
    },
    resetInactivityTimer() {
      if (this.kiosk.isKiosk) {
        clearTimeout(this.inactivityTimer)
        this.inactivityTimer = setTimeout(() => {
          // Redirect to the main page after 15 seconds of inactivity
          this.modals.timeout_modal.remaining_time = this.modals.timeout_modal.default_remaining_time
          // this.modals.share_media_selection.state = true
          this.modals.timeout_modal.state = true
          if (this.modals.timeout_modal.state) {
            const remainingTimeInterval = setInterval(() => {
              if (this.modals.timeout_modal.remaining_time > 0) {
                this.modals.timeout_modal.remaining_time -= 1
              }
            }, 1000)
            setTimeout(() => {
              const noButton = this.$refs.noButtonRef
              if (noButton) {
                noButton.click()
              }
              clearInterval(remainingTimeInterval)
            }, this.modals.timeout_modal.default_remaining_time * 1000)
          } else {
            this.modals.timeout_modal.state = true
            this.resetKioskState()
          }
        }, 20_000)
      }
    },
    kioskTimeoutYes() {
      this.modals.timeout_modal.state = false
      this.resetInactivityTimer()
    },
    kioskTimeoutNo() {
      this.resetKioskState()
    },
    resetKioskState() {
      this.modals.timeout_modal.state = false
      this.modals.timeout_modal.remaining_time = this.modals.timeout_modal.default_remaining_time
      this.modals.share_media_selection.state = false
      this.modals.share_media_selection.selected_media = null
      this.email_input = null
      this.show_qr_code = false
      this.show_email_input = false
      localStorage.removeItem('user_session_id')
      localStorage.removeItem('share_job_list')
      this.$store.commit('auth/UPDATE_CART_JOBS', 0, { root: true })
      this.$store.commit('auth/UPDATE_SHARE_JOB_LIST', [], { root: true })
      this.$store.commit('auth/UPDATE_LOA', false, { root: true })
      this.$router.push('/landing')
    },
    getLink(eventId) {
      return `${window.location.origin}/listing/${eventId}`
    },
    getKioskLink(eventId) {
      return `https://dashboard.headhuntershq.com/listing/${eventId}`
    },
    activateShareFunc(info, job_details) {
      this.modals.share_media_selection.selected_media = info.network
      this.qr_code_value = null
      this.qr_code_info = info
      if (info.network === 'email') {
        this.showEmailFlow(info, job_details)
      } else {
        this.show_email_input = false

        if (this.modals.share_media_selection.selected_media === 'whatsapp') {
          this.show_qr_code = true
        } else {
          this.generateQRCode(info, job_details)
        }
      }
    },
    cleanCycles(dirtyCycle) {
      const equivCycles = {
        yearly: 'per Year',
        monthly: 'per Month',
        weekly: 'per Week',
        daily: 'per Day',
        hourly: 'per Hour',
      }

      const cleanedCycle = !this.$isEmpty(dirtyCycle) ? equivCycles[dirtyCycle.toLowerCase()] : ''

      return cleanedCycle
    },
    showEmailQRCode() {
      this.generateQRCode(this.qr_code_info, this.qr_code_job_details)
    },
    showEmailFlow(info, job_details) {
      this.show_qr_code = false
      this.show_email_input = true
      this.qr_code_info = info
      this.qr_code_job_details = job_details
    },
    sendKioskAnalytics(data) {
      this.$http.post('/api/kiosk-analytics',
        {
          form_data: {
            user_session_id: data.user_session_id,
            page_url: data.page_url,
            message_link: data.message_link,
            destination: data.destination,
            position_ids: data.position_ids,
          },
        }).then(response => {

      })
        .catch(error => {
          // this.$handleErrorResponse(error) // Custom error handling
        })
    },
    generateShortenedURL(data) {
      this.show_qr_code = false
      this.$http.post('/api/shorten-link',
        {
          url_data: data,
        }).then(response => {
        const { link } = response.data
        this.qr_code_value = link
        this.show_qr_code = true
        
      })
        .catch(error => {
          this.qr_code_value = 'QR Code Generation Failed'
          // this.$handleErrorResponse(error) // Custom error handling
        })
    },
    generateQRCode(info, job_details) {
      this.qr_code_info = info
      this.show_email_input = false
      const jobIDs = []
      let qrCode = null
      const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || {}
      let linkText = `Check out ${this.$length(shareJobList) === 1 ? 'this role from e2i Kiosk' : 'these roles from e2i Kiosk'}\n\n`
      if (info.network === 'whatsapp') {
        Object.values(shareJobList).forEach((jobDetails, index) => {
          jobIDs.push(jobDetails.id)
          const applicationURL = jobDetails.application_url ? jobDetails.application_url : `https://dashboard.headhuntershq.com/listing/${jobDetails.id}`
          linkText += `*${jobDetails.position}*\n`
          linkText += `by: ${jobDetails.company}\n`
          linkText += applicationURL
          if (index !== shareJobList.length - 1) {
            linkText += '\n\n'
          }
        })
        qrCode = `https://api.whatsapp.com/send?phone=+65${this.modals.share_media_selection.phone_number}&text=${encodeURIComponent(linkText)}&type=phone_number&app_absent=0`
      } else if (info.network === 'telegram') {
        Object.values(shareJobList).forEach((jobDetails, index) => {
          jobIDs.push(jobDetails.id)
          const applicationURL = jobDetails.application_url ? jobDetails.application_url : `https://dashboard.headhuntershq.com/listing/${jobDetails.id}`
          linkText += `**${jobDetails.position}**\n`
          linkText += `by: ${jobDetails.company}\n`
          linkText += applicationURL
          if (index !== shareJobList.length - 1) {
            linkText += '\n\n'
          }
        })
        qrCode = `https://t.me/share/url?url=%20&text=${encodeURIComponent(linkText)}`
        // this.qr_code_value = `https://t.me/share/url?url=${'https://www.google.com'}&text=${encodeURIComponent('\n' + linkText)}`
      } else {
        Object.values(shareJobList).forEach((jobDetails, index) => {
          jobIDs.push(jobDetails.id)
          const applicationURL = jobDetails.application_url ? jobDetails.application_url : `https://dashboard.headhuntershq.com/listing/${jobDetails.id}`
          linkText += `${jobDetails.position}\n`
          linkText += `by: ${jobDetails.company}\n`
          linkText += applicationURL
          if (index !== shareJobList.length - 1) {
            linkText += '\n\n'
          }
        })
        const heading = 'Interested Roles from e2i Kiosk'
        qrCode = `mailto:${this.email_input}?subject=${encodeURIComponent(heading)}&body=${encodeURIComponent(linkText)}`
      }
      this.generateShortenedURL(qrCode)

      const page_url = `${window.location.origin}${this.$route.fullPath}`
      let user_session_id = localStorage.getItem('user_session_id') || null
      if (!user_session_id) {
        const sessionID = uuidv4().toString()
        localStorage.setItem('user_session_id', sessionID)
        user_session_id = sessionID
      }

      const data = {
        user_session_id,
        page_url,
        position_ids: jobIDs,
        message_link: this.qr_code_value,
        destination: info.network,
      }

      this.sendKioskAnalytics(data)
      // const linkText = this.getKioskLink(job_details.id)
      // const company_name = job_details.company ? job_details.company : job_details.company_name
      // if (info.network === 'whatsapp') {
      //   const share_text = `Check out this ${job_details.position} role by ${company_name} posted via HeadHunters HQ.\n\n${linkText}`
      //   this.qr_code_value = `https://api.whatsapp.com/send?text=${encodeURIComponent(share_text)}`
      //   this.show_qr_code = true
      // } else if (info.network === 'telegram') {
      //   const share_text = `Check out this ${job_details.position} role by ${company_name} posted via HeadHunters HQ.`
      //   this.qr_code_value = `https://t.me/share/url?url=${linkText}&text=${encodeURIComponent(share_text)}`
      //   this.show_qr_code = true
      // } else {
      //   this.show_qr_code = false
      // }
    },
    checkProfileCompletion() {
      let incomplete = true
      try {
        incomplete = this.$store.state.auth.ActiveUser.profile_status.incomplete
      } catch (error) {
        incomplete = true
      }
      return incomplete
    },
    handleResumePondInit() {
      const { id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      this.$refs['pond-resume']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()

            singleUpload(file, 'user-resume', `${id}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })
    },
    uploadResume(applicationID, job_id, application_url, user_id) {
      const reader = new FileReader()
      reader.onload = e => {}
      const fd = new FormData()
      fd.append('application_id', applicationID)
      fd.append('resume_file', this.resume_file)
      fd.append('email', email)
      delete this.$http.defaults.headers.common.Authorization
      this.$toast({
        component: this.$toastContent,
        props: {
          title: 'Resume Upload',
          icon: 'AlertCircleIcon',
          text: 'Your resume file is uploading in the background',
          variant: 'warning',
        },
      })
      this.$http
        .post('/api/attach-resume', fd)
        .then(response => {
          const result = response.data
          if (result.success) {
            this.successfulApplication(
              response,
              job_id,
              application_url,
              user_id,
            )
          } else {
            this.failedApplication(response, item.application_url)
          }
        })
        .catch(error => {})
    },
    successfulApplication(response, job_id, application_url, user_id) {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Registration Alert',
            icon: 'CheckCircleIcon',
            text: response.data.message,
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )

      //   window.location.reload()
      if (this.$route.name === 'page-event') {
        if (this.registered && this.registered.length > 0) {
          try {
            this.registered.push(user_id)
          } catch (error) {
          }
        } else {
          this.registered = [user_id]
        }

        this.$emit('application-update', this.registered)
        this.getEvent(this.event_id)
      } else {
        if (this.registered) {
          try {
            this.registered[job_id].push(user_id)
          } catch (error) {}
        } else {
          this.registered = []
          this.registered[job_id] = [user_id]
        }
        this.$emit('update-registration', this.registered)
      }

      this.applied = true
      // this.book.text = "Registered";
      // this.book.color = "success";

      this.popupReg = false
      if (application_url) {
        // window.location.href = application_url
        window.open(application_url, '_blank').focus()
      }

      this.showEventButtons = false
      setTimeout(() => {
        this.showEventButtons = true
      }, 200)
    },
    failedApplication(response, application_url) {
      if (application_url) {
        // window.location.href = application_url
        window.open(application_url, '_blank').focus()
      }
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Registration Alert Failed',
            icon: 'AlertCircleIcon',
            text: response.data.message,
            variant: 'danger',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
    confirmApplication(args = null) {
      this.popup_quiz = false

      const currentUser = this.$store.state.auth.ActiveUser

      const user_id = currentUser.id

      const item = this.popup_event

      const all_data = {
        id: this.applicationId,
        job_id: item.job_id,
        user_id,
        interviewed: false,
        save_nric: this.save_nric,
        questions_answers: this.popup_questions,
        resume_file: this.resume_file ? this.resume_file : currentUser.resume_file,
        note_from_applicant: item.note_from_applicant,
      }

      this.popup_quiz = false
      if (!user_id) {
        this.event_id = item.job_id
        this.popupReg = true
      } else {
        this.$http
          .post('/api/book', all_data)
          .then(response => {
            const { success, application_id } = response.data
            if (success) {
              this.successfulApplication(
                response,
                item.job_id,
                item.application_url,
                user_id,
              )
              //   this.$router.go()
            } else {
              this.failedApplication(response, item.application_url)
            }
          })
          .catch(error => {
            this.$handleErrorResponse(error)
          })
      }

      this.resetApplicationForm()
    },
    cancelApplication() {
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Job Application Update',
            icon: 'AlertCircleIcon',
            text: 'Your job application has been cancelled.',
            variant: 'warning',
          },
        },
        { timeout: this.$longestTimeout },
      )
      this.resetApplicationForm()
    },
    afterUserLoggedIn() {
      if (this.popupReg === true) {
        this.popupReg = false
      }

      this.getUser(true)
    },
    joinEvent(item, event, bypass = false) {
      const { id } = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser
        : { id: null }
      // event.srcElement.innerText = 'Loading...'
      const allApplicationIDs = item.job_applications ? item.job_applications.map(application => application.applicant_id) : []

      if (!allApplicationIDs.includes(id)) {
        this.eventId = item.id
        localStorage.setItem('applied_job', JSON.stringify(item))
        if (!isUserLoggedIn()) {
          this.popupReg = true
        } else {
          // eslint-disable-next-line no-lonely-if
          if (this.checkProfileCompletion()) {
            // incomplete = true, show profile question popup instead
            this.afterUserLoggedIn()
          } else {
            if (item.application_url) {
              localStorage.setItem('application_url', item.application_url)
            }
            localStorage.setItem('current_event', item.id)
            this.popup_event = item

            if ((item.job_questions.length > 0 || item.event_question.length > 0) && !bypass) {
              const all_questions = []
              item.event_questions.forEach(question => {
                if (!this.$isEmpty(question.question)) {
                  if (!all_questions.includes(question.question)) {
                    all_questions.push(question.question)
                  }
                }
              })
              item.job_questions.forEach(question => {
                if (!this.$isEmpty(question.question)) {
                  if (!all_questions.includes(question.question)) {
                    all_questions.push(question.question)
                  }
                }
              })

              this.popup_questions = []

              const { nric_no } = this.$store.state.auth.ActiveUser
                ? this.$store.state.auth.ActiveUser
                : { nric_no: false }

              all_questions.map(quiz => {
                if (nric_no && quiz.toLowerCase().includes('nric')) {
                  // this.popup_questions.push({ question: quiz, answer: nric_no })
                  this.save_nric = false
                } else {
                  this.popup_questions.push({ question: quiz, answer: '' })
                }
              })
              this.popup_quiz = false
              setTimeout(() => {
                this.popup_quiz = true
              }, 200)

              this.$emit('open-job-form', {
                questions: this.popup_questions,
                save_nric: this.save_nric,
              })
              if (this.popup_questions.length > 0) {
                // this.popup_quiz = false
                // setTimeout(() => {
                //   this.popup_quiz = true
                // }, 200)

                // this.$emit('open-job-form', {
                //   questions: this.popup_questions,
                //   save_nric: this.save_nric,
                // })
              }
            } else {
              if (this.resume_choice === 'new-resume') {
                if (this.$isEmpty(this.resume_file)) {
                  this.$toastDanger('Incomplete Form', 'Please upload your resume.')
                  return
                }
              }
              this.$bvModal
                .msgBoxConfirm(
                  `You are about to apply for ${item.position} job. Do you want to proceed?`,
                  {
                    title: 'Confirm Job Application',
                    okVariant: 'warning',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                  },
                )
                .then(value => {
                  if (value) {
                    this.confirmApplication()

                    // ! temporary work around, to solve the green button Apply now button not changing to Applyied
                    //   if (item.refresh_page) {
                    //     this.$router.go()
                    //   }
                  } else {
                    this.cancelApplication()
                  }
                })
            }
          }
        }
      } else {
        this.$toast({
          component: this.$toastContent,
          props: {
            title: 'Job Application Update',
            icon: 'AlertCircleIcon',
            text: 'You have already applied for this job.',
            variant: 'warning',
          },
        })
      }
    },
    resetApplicationForm() {
      this.applicationId = null // reset, so when applying for the next one doesn't cause error
      this.resume_file = null
      this.resume_choice = 'existing-resume'
    },
    getUser(isLogin = false, isRegister = false) {
      const { token } = this.$store.state.auth.ActiveUser
        ? this.$store.state.auth.ActiveUser
        : { token: null }
      if (token) {
        this.$http.defaults.headers.common.Authorization = `Token ${token}`
        const url = isRegister || isLogin ? '/api/user?bypass_session=yes' : '/api/user'
        this.$http
          .get(url)
          .then(response => {
            if (response.data.errors) {
              if (
                response.data.errors[0] === 'User not found'
                || response.data.errors[0] === 'Could not validate credentials'
              ) {
                localStorage.removeItem('userInfo')
                localStorage.removeItem('accessToken')
              }
            } else {
              this.$store.commit('auth/UPDATE_USER_INFO', response.data)

              this.current_event = localStorage.getItem('current_event')
                ? localStorage.getItem('current_event')
                : ''

              const { account_type } = this.$store.state.auth.ActiveUser
              if (account_type === this.$AccountTypes.APPLICANT) {
                this.normal = true
              } else {
                this.normal = false
              }
              const event = JSON.parse(localStorage.getItem('applied_job'))
              this.$emit('login-state', true)
              if (isLogin && account_type === this.$AccountTypes.APPLICANT) {
                if (this.checkProfileCompletion()) {
                  // if incomplete = true, prepare question to ask
                  this.prepareProfileQuestions()
                } else {
                  // ! temporary work around, to solve the green button Apply now button not changing to Applyied
                  this.popupProfileQuestions = false

                  this.joinEvent(event, null)
                }
              }
              if (isRegister) {
                this.prepareProfileQuestions()
              }
              if (event && Object.keys(event).length > 0) {
                // this.popupProfileQuestions = false
                // this.joinEvent(event, null)
              }
            }
          })
          .catch(error => {

          })
      }
    },

    afterUserRegistered() {
      if (this.popupReg === true) {
        this.popupReg = false
      }
      this.getUser(false, true)
    },
    async prepareProfileQuestions() {
      await this.getEmployments()
      await this.getEducations()

      this.profileQuestionList = []
      this.user = this.$store.state.auth.ActiveUser
      if (!this.user.resume_file && this.normal === true) {
        this.profileQuestionList.push('upload_resume')
      } else {
        this.viewUploadedResume()
      }

      if (!this.user.gender || this.user.gender === 'Not Set') {
        this.profileQuestionList.push('gender')
      }

      if (
        !this.user.employment_status
        || this.user.employment_status === 'Not Set'
      ) {
        this.profileQuestionList.push('employment_status')
      }
      if (!this.user.citizenship || this.user.citizenship === 'Not Set') {
        this.profileQuestionList.push('citizenship')
      }

      // else if (this.user.citizenship.toLowerCase() === 'others' && this.$isEmpty(this.user.citizenship_others)) {
      //   this.profileQuestionList.push('citizenship')
      // }

      if (!this.user.preferred_location || this.user.preferred_location.length <= 0) {
        this.profileQuestionList.push('preferred_location')
      }

      if (this.$isEmpty(this.user.preferred_industry) || this.user.preferred_industry.length <= 0) {
        this.profileQuestionList.push('preferred_industry')
      }

      if (
        this.$isEmpty(this.user.preferred_position)
      ) {
        this.profileQuestionList.push('preferred_position')
      }

      if (!this.user.postal_code || this.user.postal_code === 'Not Set') {
        this.profileQuestionList.push('postal_code')
      }

      if (!this.user.date_of_birth || this.user.date_of_birth === 'Not Set') {
        this.profileQuestionList.push('date_of_birth')
      }

      if (!this.user.highest_qualification || this.user.highest_qualification === 'Not Set') {
        this.profileQuestionList.push('highest_qualification')
      }

      if (
        !this.user.availability_number || !this.user.availability_period
        || this.user.availability_number === 'Not Set'
      ) {
        this.profileQuestionList.push('availability')
      }

      if (this.employmentInfo && this.employmentInfo.length <= 0 || !this.employmentInfo) {
        this.profileQuestionList.push('employments')
      }

      if (this.educationInfo && this.educationInfo.length <= 0 || !this.educationInfo) {
        this.profileQuestionList.push('educations')
      }

      if (this.profileQuestionList.length > 0) {
        // not complete, show popup, 1. upload_resume
        this.profileQuestionList.push('submit') // last page
        this.currentProfileQuestion = this.profileQuestionList[0] // show the first question

        this.$emit('open-completion-form', true)
        this.popupProfileQuestions = true
      } else {
        // completed
        this.popupProfileQuestions = false
      }
    },

    async viewUploadedResume() {
      const fileUrl = await this.$generateDownloadUrl(this.user.resume_file)
      if (this.user.resume_file && this.user.resume_file.endsWith('pdf')) {
        this.document_type = 'pdf'
        this.file_source = fileUrl
      } else {
        this.document_type = 'docs'
        this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}`
      }
    },

    // employments and educations
    async getEmployments() {
      await this.$http
        .get('/api/employment-data')
        .then(response => {
          this.employmentInfo = response.data.message
          this.isLoading = false
          // this.$forceUpdate();
        })
        .catch(error => {})
    },
    async getEducations() {
      await this.$http
        .get('/api/education-data')
        .then(response => {
          this.educationInfo = response.data.message
          this.isLoading = false
          // this.$forceUpdate();
        })
        .catch(error => {
          console.log(error)
        })
    },
    checkIfAnswered() {
      for (const quiz of this.popup_questions) {
        if (quiz.answer === '') {
          return false
        }
        if (
          quiz.question.toLowerCase().includes('nric')
          && !this.validateNRIC(quiz.answer)
        ) {
          return false
        }

        if (this.resume_choice === 'new-resume') {
          if (this.$isEmpty(this.resume_file)) {
            return false
          }
        }
      }
      return true
    },
    checkIfE2iLoaAccepted() {
      if (this.popup_event && Object.keys(this.popup_event)) {
        if (
          this.$isEmpty(this.popup_event.event_id)
            ? this.$isE2i(this.popup_event.company_name)
            : this.$isE2i(this.popup_event.organiser_name)
        ) {
          return this.isE2iLoaAccepted
        }
      }

      return false
    },
    checkIfLoaAccepted() {
      let accepted = false
      if (this.popup_event && Object.keys(this.popup_event)) {
        if (
          this.$isEmpty(this.popup_event.event_id)
            ? this.$isE2i(this.popup_event.company_name)
            : this.$isE2i(this.popup_event.organiser_name)
        ) {
          accepted = this.isE2iLoaAccepted
        } else {
          accepted = this.isCompanyLoaAccepted
        }
      }

      return accepted
    },

    validateNRIC(nricNo) {
      const regex = new RegExp(/^[STFGM]\d{7}[A-Z]$/)
      const result = regex.test(nricNo)

      return result
    },
    setApplicationId(id) {
      this.applicationId = id
    },
  },

  beforeDestroy() {
    if (this.kiosk.isKiosk) {
      // Clean up: remove event listeners and clear the timer
      clearTimeout(this.inactivityTimer)
      window.removeEventListener('mousemove', this.resetInactivityTimer)
      window.removeEventListener('keydown', this.resetInactivityTimer)
      window.removeEventListener('scroll', this.resetInactivityTimer)
      // Remove other event listeners if you added any
    }
  },
}
